import QuestionNodataCard from "../QuestionsNoDataCard";
import QuestionHeroSection from "../QuestionsHeroSection";
import { useSelector } from "react-redux";

const CreatedQuestionsList = () => {
  const { quizTestsQuestions } = useSelector(state => state.quizTest)
  return (
    <>
      <div className="col-12 px-0 question-page">
        {quizTestsQuestions && quizTestsQuestions.length === 0 ?
          <QuestionNodataCard />
          :
          <QuestionHeroSection />
        }
      </div>
    </>
  )
};

export default CreatedQuestionsList;

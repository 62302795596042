import { useEffect } from "react";
import { connect } from "react-redux";
import { matchPath, Navigate, useLocation } from "react-router-dom";

import "./App.css";
import AppRouters from "./app_routes/AllRoutes";
import { setTestUser } from "./components/user/actions";
import Header from "./components/quiz_board/component/Header/Header";
import { scheduleTokenExpiryLogout } from "./utils";

import "./assets/scss/contributor_header.scss";
import "./assets/scss/contributor_overview.scss";
import "./assets/scss/QuizBoardLanding_page.scss"
import "./assets/scss/modal.scss";
import "./assets/scss/login.scss";
import "./assets/scss/style.scss";
import "./assets/scss/quiz_Dashboard/quiz_test_dashboard.scss";
import "./assets/scss/quiz_Dashboard/quiz_board_home.scss";
import "./assets/scss/quiz_Dashboard/quiz_test_list.scss";
import "./assets/scss/quiz_Dashboard/navbar_main.scss";
import "./assets/scss/quiz_Dashboard/reviewReport.scss";
import "./assets/scss/playboard/playboard.scss";
import "./assets/scss/playboard/QuizBodyData.scss";
import "./assets/scss/playboard/Report.scss";
import "./assets/scss/quizTestPage.scss";
import "./assets/scss/Assesment/Assessment.scss";
import "./assets/scss/Assesment/assessmentTest.scss";
import "./assets/scss/Assesment/AssessmentTestSkill.scss";

const App = () => {
  const location = useLocation();

  const hideHeaderRoute = matchPath("/assessment/test/:id", location.pathname);
  const hideHeaderRouteonPlayground = matchPath("/:id/playground", location.pathname);

  const handleAutoLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userDetails");
    <Navigate to="/login" />;
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const details = localStorage.getItem("userDetails");
    if (token && details) {
      scheduleTokenExpiryLogout(token, handleAutoLogout);
    }
  }, []);

  return (
    <div className="row mx-0">
      <div className="col-12 px-0 fixed-top">
        {/* Hide the Header if either route matches */}
        {!hideHeaderRoute && !hideHeaderRouteonPlayground && <Header />}
      </div>
      <div
        className={`col-12 px-0 ${
          !hideHeaderRoute && !hideHeaderRouteonPlayground ? "mt-5" : "mt-0"
        } display-section`}
      >
        <AppRouters />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setTestUser,
};

export default connect(null, mapDispatchToProps)(App);

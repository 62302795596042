import { useSelector } from "react-redux";
import CandidatesInfoTable from "./CandidatesInfoTable.jsx";

const CandidatesTables = () => {
  const {quizTestTakens} = useSelector(state => state.quizTest);
  return (
    <>
      <div className="row mx-0 custom-table ">
        <div className="col-lg-12 d-flex justify-content-between align-items-baseline table-header table-responsive">
          <div className="col-lg-6 d-flex align-items-baseline justify-content-start">
            <h5>Candidates()</h5>
          </div>
          <div className="col-lg-6 d-flex align-items-baseline justify-content-end">
            <span>
              <i className="bi bi-search m-2"></i>
            </span>
            <span>
              <i className="bi bi-funnel m-2"></i>
            </span>
            <span>
              <i className="bi bi-arrow-repeat m-2"></i>
            </span>
            <span>
              <select className="form-select " id="accessSelect">
                <option selected>20 rows</option>
                <option value="1">*</option>
                <option value="2">*</option>
              </select>
            </span>
            <span>
              <select className="form-select " id="accessSelect">
                <option selected>8 of 11 columns</option>
                <option value="1">*</option>
                <option value="2">*</option>
              </select>
            </span>
          </div>
        </div>
        <div className="col-12 px-0">
          <CandidatesInfoTable quizTestTakens={quizTestTakens}/>
        </div>
      </div>
    </>
  )
};
export default CandidatesTables;

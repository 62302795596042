const QuestionEditHeader = ({questiontype}) => {
  return (
    <>
      <div className="col-12 p-2 mb-2 question-edit-header">
        {questiontype.length==0 ? <h5 className="pt-2">Edit multiple choice question</h5> :
        <h4>Create {questiontype} question</h4>
        }
      </div>
    </>
  )
};

export default QuestionEditHeader;

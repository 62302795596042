import QuestionEditSidebar from "./QuestionEditSidebar";
import QuestionEditFooter from "./QuestionEditFooter";
import QuestionEditForm from "./QuestionEditForm";
import QuestionEditHeader from "./QuestionEditHeader";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createQuizQuestion, editQuizQuestion, getQuizQuestionsList } from "../../../../../actions/operations";
import { useDispatch, useSelector } from "react-redux";

const QuestionCreateEdit = ({ setIsEditDrawer, questiontype = "" }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { quizQuestionDetails } = useSelector(state => state.quizTest);
  const [question, setQuestion] = useState('<p></p>');
  const [choices, setChoices] = useState(["", "", "", ""]);
  const [correctChoice, setCorrectChoice] = useState("");
  const [maxScore, setMaxScore] = useState(0);
  const [negativeScore, setNegativeScore] = useState(0);
  const [difficultyLevel, setdifficultyLevel] = useState("easy");
  const [isTimedQuestion, setisTimedQuestion] = useState(false);
  const [duration, setDuration] = useState("");
  const [questionTag, setQuestionTag] = useState("");
  const [questionId, setQuestionId] = useState("");

  const setInitialState = () => {
    setQuestion("")
    setChoices(["", "", "", ""])
    setCorrectChoice("")
    setMaxScore(0)
    setNegativeScore(0)
    setdifficultyLevel("")
    setisTimedQuestion(false)
    setQuestionTag("")
  }

  useEffect(() => {
    if (quizQuestionDetails) {
      if (questiontype.length !== 0) {
        setInitialState();
      } else {
        setQuestion(quizQuestionDetails.title || "");
        setChoices([
          quizQuestionDetails.option1 || "",
          quizQuestionDetails.option2 || "",
          quizQuestionDetails.option3 || "",
          quizQuestionDetails.option4 || ""
        ]);
        setCorrectChoice(quizQuestionDetails.correct_answer || "");
        setMaxScore(quizQuestionDetails.maxScore || 0);
        setNegativeScore(quizQuestionDetails.negativeScore || 0);
        setdifficultyLevel(quizQuestionDetails.level || "easy");
        setisTimedQuestion(quizQuestionDetails.isDuration || false);
        setQuestionTag(quizQuestionDetails.questionTag || "");
        setQuestionId(quizQuestionDetails._id || "");
      }
    }
  }, [quizQuestionDetails, questiontype]);


  const HandleAddFormData = () => {
    const FormData = {
      title: question,
      option1: choices[0],
      option2: choices[1],
      option3: choices[2],
      option4: choices[3],
      correct_answer: correctChoice,
      maxScore: maxScore,
      negativeScore: negativeScore,
      level: difficultyLevel,
      isDuration: isTimedQuestion,
      questionTag: questionTag,
      quizTest: id,
      _id: questionId,
    }
    dispatch(createQuizQuestion(FormData)).then(() => {
      dispatch(getQuizQuestionsList(id));
    })
    setInitialState();
  }

  const HandleEditFormData = () => {
    const FormData = {
      title: question,
      option1: choices[0],
      option2: choices[1],
      option3: choices[2],
      option4: choices[3],
      correct_answer: correctChoice,
      maxScore: maxScore,
      negativeScore: negativeScore,
      level: difficultyLevel,
      isDuration: isTimedQuestion,
      tags: questionTag,
      quizTest: [id],
      _id: questionId
    };
     dispatch(editQuizQuestion(FormData)).then(()=>{
        dispatch(getQuizQuestionsList(id));
    })
    setIsEditDrawer(false)
  }

  return (
    <>
      <div className="row mx-0 overflow-hidden question-edit">
        <div className="col-lg-12 px-0">
          <QuestionEditHeader questiontype={questiontype} />
        </div>
        <div className="col-lg-12 p-0 d-lg-flex justify-content-between main-section">
          <div className="col-lg-8 px-0">
            <QuestionEditForm setQuestion={setQuestion} setChoices={setChoices} setCorrectChoice={setCorrectChoice} choices={choices} question={question} correctChoice={correctChoice} />
          </div>
          <div className="col-lg-4 px-0 ms-2">
            <QuestionEditSidebar maxScore={maxScore} setMaxScore={setMaxScore} negativeScore={negativeScore} setNegativeScore={setNegativeScore} difficultyLevel={difficultyLevel} setdifficultyLevel={setdifficultyLevel}
              isTimedQuestion={isTimedQuestion} setisTimedQuestion={setisTimedQuestion} questionTag={questionTag} setQuestionTag={setQuestionTag} duration={duration} setDuration={setDuration}
            />
          </div>
        </div>
        <div className="col-lg-12 px-0">
          <QuestionEditFooter
            setIsEditDrawer={setIsEditDrawer}
            questiontype={questiontype}
            HandleAddFormData={HandleAddFormData}
            HandleEditFormData={HandleEditFormData}
          />
        </div>
      </div>
    </>
  )
};

export default QuestionCreateEdit;


const TestAnalyticsHeader =({activeTab})=>{
  return(
    <>
    <div className="col-12 px-0 py-3 analytics-header">
      <h5>{activeTab}</h5>
      <div className="col-lg-12 px-0 d-flex justify-content-between">
        <p>This the overview of the test based on the candidates performance,time taken to complete the test ,etc.</p>
        <span>
        <i className="bi bi-download mx-2"></i>
        <i className="bi bi-link-45deg mx-2"></i>
        </span>
      </div>
    </div>
    </>
  )
};

export default TestAnalyticsHeader;
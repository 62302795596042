import { Dropdown, Space, Menu } from "antd";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useSelector } from "react-redux";

const ReportReviewHeader =() =>{
  const {candidateReportReviewDetails} = useSelector(state => state.quizTest);

  const menu = (
    <Menu>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="del">Delete</Menu.Item>
    </Menu>
  );
  const { firstName, lastName ,imgUrl} = candidateReportReviewDetails?.user || {};
  return(
    candidateReportReviewDetails &&
    <div className="col-12 px-0 d-lg-flex align-items-center report-header ">
      <div className="col-lg-6 d-flex justify-content-start align-items-center">
        <img src={imgUrl} alt="user-img" width="50px" height="50px"/>
        <span className="px-2">{firstName} {lastName}</span>
        <span className="px-2 shortlisted-tag">shortlisted</span>
      </div>
      <div className="col-lg-6 d-flex justify-content-end align-items-center">
        <i className="bi bi-download px-2"></i>
        <i className="bi bi-share px-2"></i>
        <span className="px-2"><button className="send-email-btn">Send Email</button></span>
        <span className="px-2" ><button className="btn btn-primary">Get Interview Link</button></span>
        <Dropdown  overlay={menu} trigger={["click"]}>
              <Space>
                <PiDotsThreeOutlineVerticalFill />
              </Space>
        </Dropdown>
      </div>
    </div>
  );
}

export default ReportReviewHeader;
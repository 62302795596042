import React from 'react'

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="row mx-0 text-center">
        <div className="col-12">
          <img
            src={require('../assets/images/errorfallback.png')}
            alt="Error"
            className="img-fluid"
            style={{ maxWidth: '320px' }}
          />
          <h1 className="display-5 text-danger">Oops! Something went wrong</h1>
          <p className="lead text-muted mb-4">
            We're sorry for the inconvenience. Our team has been notified.
          </p>
          <pre className="text-muted mb-4" style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}>
            {error.message}
          </pre>
          <div
            onClick={resetErrorBoundary}
            className="btn btn-primary"
          >
            Try Again
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorFallback;
const TestPerformanceMetric =()=>{
  return(
    <>
      <div className="col-12 p-3 mb-3 metric-performnace table-responsive-sm">
        <h6>Test Performance Metric</h6>
        <div className="col-12 d-flex">
          <div className="col-lg-6 col-sm-3">
            <span className="d-flex align-item-baseline">
              <span><h4>10%</h4></span>
              <span className="detail">of the total invited candiadtes have attempted the test</span>
            </span>
            <span className="d-flex align-item-baseline">
              <span className="drop"><h6>61%</h6></span>
              <span className="detail">drop w.r.t all tests in HackerEarth Assessments</span>
            </span>
          </div>
          <div className="col-lg-6 col-sm-3">
            <span className="d-flex align-item-baseline">
              <span><h4>61%</h4></span>
              <span className="detail">of the total attempted candiadtes have cleared the cutoff  score</span>
            </span>
            <span className="d-flex align-item-baseline">
              <span className="rise"><h6>29%</h6></span>
              <span className="detail">rise w.r.t all tests in HackerEarth Assessments</span>
            </span>
          </div>
        </div>
      </div>
    </>
  )
};

export default TestPerformanceMetric;
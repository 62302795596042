import { useState } from "react";
// import CreateQuestionModal from "./created_questions_list/CreateQuestionModal";
import QuestionTypesDrawer from "./Drawers/QuestionTypesDrawer";
import { useSelector } from "react-redux";

const QuestionHeader = () => {
  // const [openModal, setOpenModal] = useState(false);
  const { quizTestsQuestions } = useSelector((state) => state.quizTest);
  const [open, setOpenDrawer] = useState(false);

  // const totalScore = quizTestsQuestions.reduce((sum, ques) => sum + parseFloat(ques.maxScore || 0), 0);

      // const toggleModal = () => {
  //   setOpenModal(!openModal);
  // };
  return (
    <>
      <div className="col-12 py-2 px-0 mb-2  align-items-baseline questions-header">
        <div className="row mx-0 px-2">
          <div className="col-lg-6  px-0 d-flex align-items-baseline ">
            <h5>Questions ({quizTestsQuestions?.length})</h5>
            <span className="m-2">Total Score : | </span>
          </div>
          <div className="col-lg-6  px-0 d-flex justify-content-end align-items-center">
            <button
              type="button"
              onClick={() => {
                setOpenDrawer(true);
              }}
            >
              Create a new question
            </button>
            <button type="button">Choose from librabry</button>
            <a href="">
              <i className="bi bi-filter-left"></i>
              <i className="bi bi-plus"></i>
            </a>
          </div>
        </div>
      </div>
      <QuestionTypesDrawer open={open} setOpenDrawer={setOpenDrawer} />
      {/* <CreateQuestionModal open={openModal} toggle={toggleModal} /> */}
    </>
  )
};

export default QuestionHeader;

import { jwtDecode } from "jwt-decode";

export const generateQueryParams = (query) => {
  let str = "?";
  for (var xyz in query) {
    if (query[xyz] != null) {
      str = `${str}${xyz}=${query[xyz]}&`;
    }
  }
  str = str.slice(0, -1);
  return str !== "" ? str : "";
};

const getTokenExpiration = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp) {
      return decodedToken.exp * 1000; 
    }
  } catch (error) {
    console.error('Invalid token', error);
  }
  return null;
}

export const scheduleTokenExpiryLogout = (token, logoutFunction) => {
  const expirationTime = getTokenExpiration(token);
  if (expirationTime) {
    const timeLeft = expirationTime - Date.now();
    if (timeLeft >= 0) {
      setTimeout(logoutFunction, timeLeft);
    } else {
      logoutFunction();
    }
  }
}
const QuizBoardLandingHeroSection = () => {
  return (
    <>
      <div className="row mx-0 QuizBoardLandingHeroSection pb-5">
        <div className="col-12 px-0 pb-5">
          <div className="text-center justify-content-center align-items-center QuizBoardLandingHeroSection-A">
            <h1>Welcome to QuizTest</h1>
            <h3>Challenge Your Knowledge with QuizTest</h3>
            <p>
              Explore, Learn, and Compete with Thousands of Quizzes on Various
              Topics
            </p>
            <div>
              <button className="btn btn px-5 py-2">Take a Quiz Now</button>
            </div>
          </div>
          <div class="custom-shape-divider-top-1731340048">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};
export default QuizBoardLandingHeroSection;

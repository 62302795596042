import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import userReducer from "../components/user/reducers";
import quizTestReducer from "../components/quiz_board/reducers";
import { testUserReducer } from "../components/quiz_board/reducers/TestUser";
import timerReducer from "../components/Assessment/Reducer/TimerReducer";


export default combineReducers({
  form: formReducer,
  user: userReducer,
  quizTest: quizTestReducer,
  testUser: testUserReducer,
  timer:timerReducer,
});

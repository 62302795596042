import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const candidate_info = [
  {
    id: "01",
    name: "ms0009898755676",
    email: "ms0009898755676@gmail.com",
    finished_at: "Mar 09, 2022 4:00 PM IST (Asia/Kolkata)",
    status: "Review Pending",
    score: "95",
    interview_detail: "https://www.google.com",
    attempt: "95%",
    duration: "25 min",
  },
  {
    id: "02",
    name: "ms0009898755676",
    email: "ms0009898755676@gmail.com",
    finished_at: "Mar 09, 2022 4:00 PM IST",
    status: "Review Pending",
    score: "95",
    interview_detail: "https://www.google.com",
    attempt: "95%",
    duration: "25 min",
  },
];

const CandidatesInfoFormatter = (cell, row, navigate) => {
  const handleClick = () => {
    navigate(`/quiz/test/review/report/${row._id}`);
  };
  return (
    <div className='d-flex justify-content-between'>
      <span>{row.id}</span>
      <span>
        <div onClick={handleClick}>
          {row.user.firstName}{row.user.lastName}
        </div>
        <div style={{ fontSize: 'smaller', color: 'blue' }}>{row.user.email}</div>
      </span>
    </div>
  );
};

const InterviewDetails = (cell, row) => (
  <div>
    <a href={row.interview_detail} style={{ textDecoration: "none" }}>Get Interview Link</a>
  </div>
);

const ScoreFormatter = (cell, row) => (
  <div className='d-flex'>
    <div style={{ color: "lightgreen", fontWeight: "bolder" }}>
      <i className="bi bi-check-circle m-2"></i>
    </div>
    <div>{row.score}</div>
  </div>
);

const headerStyle = { width: '15%', color: 'grey', fontWeight: '400' };

const columns = (navigate) => [
  {
    dataField: "info",
    text: "# &emsp; Candidate Info",
    formatter: (cell, row) => CandidatesInfoFormatter(cell, row, navigate),
    headerStyle: { color: 'grey', fontWeight: '400', width: "22%" },
  },
  {
    dataField: 'finished_at',
    text: 'Finished At',
    headerStyle: headerStyle,
  },
  {
    dataField: 'status',
    text: 'Status',
    headerStyle: headerStyle,
  },
  {
    dataField: 'quizTest.score',
    text: 'Score',
    formatter: ScoreFormatter,
    headerStyle: { color: 'grey', fontWeight: '400', width: "10%" },
  },
  {
    dataField: 'interview_detail',
    text: 'Interview Detail',
    formatter: InterviewDetails,
    headerStyle: { width: '20%', color: 'grey', fontWeight: '400' },
  },
  {
    dataField: 'attempt',
    text: 'Attempt',
    headerStyle: headerStyle,
  },
  {
    dataField: 'duration',
    text: 'Duration',
    headerStyle: headerStyle,
  },
];

const selectRow = {
  mode: 'checkbox',
  clickToSelect: true,
};

const CandidatesInfoTable = ({ quizTestTakens }) => {
  const navigate = useNavigate();
  return (
    <div className="custom-table table-responsive-sm">
      <BootstrapTable
        keyField='id'
        data={quizTestTakens}
        columns={columns(navigate)}
        selectRow={selectRow}
        bordered={false}
      />
    </div>
  );
};

export default CandidatesInfoTable;

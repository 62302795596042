import React, { useState } from "react";
import { Button, Drawer } from "antd";
import QuestionTypesSidebar from "../QuestionTypesSidebar";
import "../../../../../../../assets/scss/quiz_Dashboard/questionViewDrawer.scss";

const QuestionTypesDrawer = ({open, setOpenDrawer}) => {
  return(
    <>
      <Drawer
        destroyOnClose
        placement="right"
        open={open}
        onClose={() => setOpenDrawer(false)}
        className="custom-drawer"
        width={"40%"}
       
      >
        <div className="drawer-header">
          <Button className="close-button" onClick={() => setOpenDrawer(false)}>
            X
          </Button>
        </div>
        <QuestionTypesSidebar />
      </Drawer>
    </>
  )
};

export default QuestionTypesDrawer;
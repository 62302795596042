import { useEffect, useState } from "react";
import { getQuizTestDetails } from "../quiz_board/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import Loader from "../../shared_components/Loader";

const QuizTestPage = () => {
  const { quizTestDataLoading, currentQuizTest } = useSelector(
    (state) => state.quizTest
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate(); // Hook for navigation
  const [isVisited, setIsVisited] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    dispatch(getQuizTestDetails({ quizTest: id, mode: "test" }));
  }, [dispatch, id]);

  const handleStartTestClick = () => {
    if (currentQuizTest?.quizQuestions?.length === 0 ) {
      setMessage("Currently, the test is unavailable");
    } else {
      navigate(`/${id}/playground`); 
    }
  };

  const formatDurationReadable = (minutes) => {
    const hrs = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hrs > 0 ? `${hrs} hour${hrs > 1 ? "s" : ""}` : ""} ${
      mins > 0 ? `${mins} minute${mins > 1 ? "s" : ""}` : ""
    }`.trim();
  };

  const formattedDuration = formatDurationReadable(currentQuizTest?.duration);

  if (quizTestDataLoading) {
    return <Loader />; // Display loader while data is loading
  }

  return (
    <>
     <div className="row mx-0 quiz-test-preview-detail-Main">
      <div className="col-12 px-0">
              {/* <div className="row mx-0 mb-4 mt-2 quiz-test-preview-header">
        <nav className="col-12 p-2 mt-2 d-lg-flex gap-lg-0 gap-3 justify-content-center nav">
          <div className="col-lg-1 text-left ">
            <i className="bi bi-lightbulb mr-1"></i>
          </div>
          <div className="col-lg-3">
            <button className="button-preview">Preview</button>
          </div>
          <div className="col-lg-6">
            <span className="p-3 timer">
              {currentQuizTest?.duration ? formattedDuration : "00.01.00"}{" "}
            </span>
          </div>
        </nav>
        <div className="col-12 p-2 d-lg-flex justify-content-around details-div">
          <span className="d-flex align-items-baseline">
            {isVisited ? (
              <i className="bi bi-check2-circle mx-2 h4"></i>
            ) : (
              <i className="bi bi-1-circle"></i>
            )}
            Terms & Condition
          </span>
          <span className="d-flex align-items-baseline">
            <i className="bi bi-2-circle mx-2 h4"></i> Step2
          </span>
          <span className="d-flex align-items-baseline">
            <i className="bi bi-3-circle mx-2 h4"></i> Read Instructions and
            start test
          </span>
        </div>
      </div> */}
      <div className="row mx-0 px-4 mb-4 pt-5 quiz-test-preview-detail">
        <div className="col-12 p-3 d-lg-flex info-card align-items-center">
          <div className="col-lg-7 col-12 p-2">
            <h4 className="text-capitalize lh-base">{currentQuizTest?.quizTitle}</h4>
            {/* <p className="m-0 pt-1 date-time-para">
              <span>
                By <b>Datacode</b>
              </span>
              <i className="bi bi-clock mx-2"></i>
              <span>Test Duration {currentQuizTest?.duration} min</span>
            </p> */}
            {/* <p className="m-0 py-2">
              For more help, contact <a href="#">akshaycoder25@gmail.com</a>
            </p> */}
            {/* <div className="py-2 highlight">
              <i className="bi bi-exclamation-circle mx-2"></i>
              <span>
                Previewing test as an admin. Send test invite to your personal
                mail for candidate view
              </span>
            </div> */}
            <div className="d-flex flex-grid gap-5 fs-5  text-left">
              <div className="">
                <p>4.7</p>
               <b><a href>(4214 reviews)</a></b>
              </div>
              <div className="border-end-dark"></div>
              <div className="">
                <p> By</p>
                <p><b>Datacode</b></p>
              </div>
              <div className="border-end-dark"></div>
              <div className="">
              <div className="d-flex gap-2">
              <i className="bi bi-clock" />
              <p>Test Duration</p>
              </div>
              <b><p>{currentQuizTest?.duration} min</p></b>
              </div>
            </div>
            <div className="pt-4">
              <button
                className="p-1 start-test-btn btn"
                onClick={handleStartTestClick}
              >
                Start Test
              </button>
              {message && (
                <>
                  <div className="mt-2 text-primary">{message}</div>
                  <Link className="text-dark" to={`/quiz/tests`}>Back to Tests</Link>
                </>
              )}
            </div>
            {/* horiztonal scroll-bar */}
            {/* <div className="d-flex gap-2 align-items-center pt-2">
              <div className="border rounded-pill scroll-bar w-75"></div>
              <div className="fs-3"><i class="bi bi-flag-fill"></i></div>
            </div> */}
            {/* <div className="">
              <p>Please <a href className="text-danger">login</a> to see the progress</p>
            </div> */}
          </div>
          {/* <div className="col-lg-5 col-12 p-2 d-l g-flex flex-lg-column align-items-center justify-content-center">
            <span className="start-test">
              <span>You can Start the test in 00.05 min.</span>{" "}
              <span>Read test instructions.</span>
            </span>
            <div className="w-100 text-lg-center py-2">
              <button
                className="p-1 start-test-btn"
                onClick={handleStartTestClick}
              >
                Start Test <i className="bi bi-arrow-right-circle mx-2"></i>
              </button>
              {message && (
                <>
                  <div className="mt-2 text-primary">{message}</div>
                  <Link className="text-dark" to={`/quiz/tests`}>Back to Tests</Link>
                </>
              )}
            </div>
          </div> */}
        </div>
      </div>
      <div className="row mx-0 px-4 quiz-test-proctoring-setting">
        <div className="col-lg-12 p-4 proctoring-setting">
          <h5>
            <span className="danger-color">
              <i className="bi bi-lightbulb mr-2"></i> Important
            </span>{" "}
            Proctoring Settings{" "}
          </h5>
          <p className="py-2 m-0">
            1. All Popups/Tab/Windows switches will log you out of the test
            Automatically. We recommend that you close all programs including
            your email before you start.
          </p>
          <p className="py-2 m-0 ">
            2. This test can be taken in full screen mode only.
          </p>
        </div>
      </div>
      <div className="row mx-0 p-4">
        <div className="col-lg-12 p-3">
          <h4>Read the Instructions</h4>
        </div>
        <div className="col-lg-12 p-3">
          <h5>Test Instructions</h5>
          <ul>
            {currentQuizTest?.instructions &&
              parse(currentQuizTest.instructions)}
          </ul>
        </div>
      </div>
      </div>
     </div>
    </>
  );
};

export default QuizTestPage;

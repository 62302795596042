import { Button, Drawer } from "antd";
import { useState } from "react";
import { IoMenuOutline } from "react-icons/io5";
import QuizTestSideNavbar from "../../QuizTestSideNavbar";

const QuizTestSideNavbarDrawer = ({ setActiveTab, activeTab }) => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <div className="">
        <div className="Menu_Icon justify-content-center align-items-center text-sm-start py-2 ps-2">
          <Button onClick={() => setOpenMenu(true)} className="border-none align-items-center justify-content-center ">
            <IoMenuOutline />
          </Button>
        </div>
        <span className="Header_Menu">
          <QuizTestSideNavbar setActiveTab={setActiveTab} activeTab={activeTab} />
        </span>
        <Drawer
          placement="left"
          open={openMenu}
          width="55%"
          onClose={() => setOpenMenu(false)}
        >
          <button onClick={() => setOpenMenu(false)} className="d-flex ms-auto pt-3 btn">X</button>
          <QuizTestSideNavbar setActiveTab={setActiveTab} activeTab={activeTab} />
        </Drawer>
      </div>
    </>
  );
};

export default QuizTestSideNavbarDrawer;

import React, { useEffect, useState } from "react";
import QuestionsLevelList from "./QuestionsLevelList";
import QuizSettingandOtherSetting from "./QuizSettingandOtherSetting";
import AdvancedSettingSection from "./AdvanceSettingSection";
import QuizTestAdmins from "./QuizTestAdmin";
import QuizTestDescriptionSection from "./QuizTestDescriptionSection";
import QuizTestInstruction from "./QuizTestInstructions";
import FAQsSection from "./QuizFAQsSection";
import QuizInfoSection from "./QuizInfoSection";
import { getQuizQuestionsList, getQuizTestDetails } from "../../../../actions/operations";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../../../../shared_components/Loader";

const QuizTestOverview = ({ setActiveTab }) => {
  const { quizTestDataLoading, currentQuizTest } = useSelector((state) => state.quizTest);
  const dispatch = useDispatch();
  const { id } = useParams();


  useEffect(() => {
    dispatch(getQuizTestDetails({ quizTest: id, mode: 'creator' }));
  }, [id]);

  const handleView = (e) => {
    e.preventDefault();
    setActiveTab("questions");
  }
  return (
    <>
      {quizTestDataLoading ?
        (<Loader />)
        :
        (<div className="row mx-0 p-3 quiz-test-overview">
          <QuizInfoSection />
          <div className="col-12 d-flex py-4 px-0 align-items-baseline heading-section-quiz-test ">
            <span>
              <h5>Overview</h5>
            </span>
            <span>
              <a href="" onClick={(e) => handleView(e)} className="m-2">
                View questions
              </a>
            </span>
          </div>
          {currentQuizTest && <QuestionsLevelList currentQuizTest={currentQuizTest} />}
          <QuizSettingandOtherSetting />
          <AdvancedSettingSection />
          <QuizTestAdmins />
          <QuizTestDescriptionSection />
          <QuizTestInstruction />
          <FAQsSection />
          {/* {<CreateQuizSubHeader />} */}
          {/* {renderActiveComponent(activeTab)} */}
        </div>)
      }
    </>
  )
};

export default QuizTestOverview;

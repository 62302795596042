import QuestionEditor from "../Question/QuestionCreateEdit/QuestionEditor";
import { useState } from "react";

const QuizTestEmail = ({ setActiveTab, activeTab }) => {
  const [template,setTemplate]=useState("Invite Email")
  
  return (
      <>
        <div className="row mx-0 p-3 email-tab-section">
          <div className="col-12 px-0 mb-3">
            <h4>Email templates</h4>
          </div>
          <div className="col-12 px-0 d-flex pb-3 flex-column">
            <span className="select-template">Select Template</span>
            <select className="form-select py-1">
              <option selected>Invite Email</option>
              <option onClick={()=>{setTemplate("Remainder Email")}}>Remainder Email</option>
              <option onClick={()=>{setTemplate("Invite Cancel Email")}}>Invite Cancel Email</option>
              <option onClick={()=>{setTemplate("Thankyou  Email")}}>Thankyou  Email</option>
              <option onClick={()=>{setTemplate("Shortlist Email")}}>Shortlist Email</option>
              <option onClick={()=>{setTemplate("Archieve Email")}}>Archieve Email</option>
            </select>
          </div>
          <div className="col-lg-12 px-0 d-flex form-section">
            <div className="col-lg-9 p-4">
              <form>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">From</label>
                  <input type="email" className="form-control" id="exampleInputEmail1" value="Datacode<support@hackerearth.com>" disabled aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                  <label for="exampleInputPassword1" className="form-label">Subject</label>
                  <input type="email" className="form-control" id="exampleInputEmail1" value="Invitation for {@TestName@} by DataCode" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                  <label for="exampleInputPassword1" className="form-label">Email Template</label>
                  <QuestionEditor />
                </div>
                <button type="submit" className="btn btn-primary py-0">Save Changes</button>
              </form>
            </div>
            <div className="col-lg-3  p-4 note-section">
              <p><strong>Note</strong>: Company name can be changed from company information in Settings.<a href="">Review here</a> </p>
              <p><strong>Note</strong>: The String &#123;&#64;TestName&#64;&#125; &#123;&#64;FullName&#64;&#125; &#123;&#64;StartTime&#64;&#125;
              &#123;&#64;EndTime&#64;&#125; &#123;&#64;TestDuration&#64;&#125; &#123;&#64;TestLink&#64;&#125;&#123;&#64;UserLoginDetails&#64;&#125;
              will be replaced by actual TestName, candidated Full Name, StartTime, EndTime,TestDuration, Link to test page, Login Details for new user respectively,
               so do not remove this </p>
            </div>
          </div>
        </div>
      </>
  )
};

export default QuizTestEmail;


import { useEffect } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { SiWindows11 } from "react-icons/si";
const QuestionChange = ({
  quizTestsQuestions = [],
  setCurrentPage,
  currentPage,
  answers,
}) => {
  console.log(currentPage)
  const itemsPerPage = 1;
  const totalPages = Math.ceil(quizTestsQuestions.length / itemsPerPage);

  const isAnswered = (questionId) => {
    return answers.some(answer => answer.questionId === questionId && answer.selectedAnswer !== null);
  };

  const isFlagged = (questionId) => {
    return answers.some(answer => answer.questionId === questionId && answer.flag);
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getQuestionStatus = (index) => {
    const questionId = quizTestsQuestions[index]?._id;  // Assuming each question has an ID
    let pageClass = 'default-color';  // Default class for unvisited/unanswered questions

    if (isAnswered(questionId)) {
      pageClass = 'green-color';  // Answered questions
    } else if (isFlagged(questionId)) {
      pageClass = 'red-color';  // Flagged questions
    }
    return pageClass
  }

  return (
    <div className="row mx-0">
      <div className="col-lg-12 d-flex align-items-center justify-content-center gap-2">
        <div className="pagination d-flex">
          <span
            className="page-link align-items-center justify-content-center border rounded pt-0 fs-5"
            onClick={() => handlePreviousPage()}  // Go to previous page
            disabled={currentPage === 0}  // Disable if on the first page
          >
            <FaAngleLeft />
          </span>

          {[...Array(totalPages)].map((_, index) => {
            return (
              <span
                key={index + 1}
                className={`page-link question-change table-responsive ${getQuestionStatus(index)} ${currentPage === index ? "active" : ""
                  }`}
                onClick={() => setCurrentPage(index)}
                style={{ cursor: 'pointer' }}
              >
                {index + 1}
              </span>
            );
          })}
          <span
            className="page-link align-items-center justify-content-center border rounded pt-0 fs-5"
            onClick={() => handleNextPage()}  // Go to next page
            disabled={currentPage === totalPages - 1}  // Disable if on the last page
          >
            <FaAngleRight />
          </span>
        </div>
        <div className="fs-5 text-center pagination page-link align-items-center justify-content-center rounded pt-0">
          <span className="justify-content-between fs-5 text-center px-0">
            <SiWindows11 />
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuestionChange;


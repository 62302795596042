import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { dataIndexOf } from "react-widgets/cjs/Accessors";
import QuizTestSideNavbarDrawer from "./Question/Drawers/QuizTestSideNavbarDrawer";

const QuizTestHeader = () => {
  const { currentQuizTest } = useSelector((state) => state.quizTest);
  const { id } = useParams();

  useEffect(() => {
  }, [id, currentQuizTest]);

  return (
    <>
      <div className="row mx-0 mt-3 pb-1 quiz-test-header">
        <div className="col-2 py-1 d-flex">
          <div className="col-lg-12 my-0 text-start  justify-content-center align-items-center">
            <Link to="/quiz/tests" className="d-flex gap-1 ">
              <i className="bi bi-arrow-left"></i> Back
            </Link>
          </div>
        </div>
        <div className="col-lg-10 py-1 d-lg-flex justify-content-between align-items-center">
          <div className="col-lg-4 px-0 text-start mx-0 d-flex">
            <li></li>
            <h5 className="m-0">{currentQuizTest && currentQuizTest?.quizTitle}</h5>
          </div>
          <div className="col-lg-8 px-0 d-flex justify-content-end align-items-center">
            <a href="" className="mx-3">
              <i className="bi bi-copy"></i> CopyLink
            </a>
            <Link to={`/${id}/playground`} className="mx-3">
              <i className="bi bi-box-arrow-up-right"></i> Preview
            </Link>
            <button className="mx-2 px-3">
              <i className="bi bi-person-plus"></i> Invite Candidate
            </button>
            <a>
              <i className="bi bi-three-dots mx-3"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  )
};

export default QuizTestHeader;

import { Link } from "react-router-dom";
import image from "../../../../assets/images/quizTest-img.webp";
import assimage from "../../../../assets/images/assements-img.png";

const QuizBoardCards = () => {
  return (
    <>
      <div className="row mx-0 mt-0 d-flex justify-content-center ">
        <div className="col-8 d-flex justify-content-between task-div-section">
          <div className="col-5 d-flex flex-column task-div ">
            <div className="img-div">
              <img src={assimage} alt="Assessment" className="img-fluid" />
            </div>
            <div className="text-section py-5 ">
              <Link to="/assessments">
                <h6>Assessments</h6>
              </Link>
              <span>View and Attempt your Assessments</span>
            </div>
          </div>
          <div className="col-5 d-flex flex-column task-div">
            <div className="img-div">
              <img src={image} alt="Quiz Test" className="img-fluid" />
            </div>
            <div className="text-section py-5">
              <Link to="/quiz/tests">
                <h6>Quiz Test</h6>
              </Link>
              <span>View and Attempt your Quiz Test</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizBoardCards;

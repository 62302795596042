const QuizInfoSection = () => {
  return (
    <div className="col-12 p-2 d-flex info-section">
      <span className="mx-1">
        <a href="">
          <i className="bi bi-exclamation-circle"></i>{" "}
        </a>
      </span>
      <span className="ml-1">
        To list the Content on the College Challenges Page{" "}
        <a href="">send us request</a>
      </span>
    </div>
  )
};

export default QuizInfoSection;
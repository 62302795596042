import { useEffect, useState } from "react";

const AssessmentCardVerified = ({ quizTestsList }) => {
  const [verifiedTests, setVerifiedTests] = useState([]);
  const testUser = JSON.parse(localStorage.getItem("userDetails"));
  const reports = testUser?.data.quizReports;

  useEffect(() => {
    if (quizTestsList && reports) {
      // Find verified tests by matching quizTest._id in reports with quizTestsList._id
      const verifiedTests = reports.reduce((verified, report) => {
        const verifiedTest = quizTestsList.find(
          (test) => test._id === report.quizTest && test.quizCategory === "assessment"
        );

        if (verifiedTest) {
          verified.push({ title: verifiedTest.quizTitle, isPassed: report.isPassed });
        }
        return verified;
      }, []);

      // Only update state if verifiedTests has changed
      setVerifiedTests((prevVerifiedTests) => {
        const isSame =
          prevVerifiedTests.length === verifiedTests.length &&
          prevVerifiedTests.every((test, index) => test.title === verifiedTests[index].title && test.isPassed === verifiedTests[index].isPassed);
        
        if (!isSame) {
          return verifiedTests;
        }
        
        return prevVerifiedTests; // Avoid unnecessary re-render
      });
    }
  }, [quizTestsList, reports]);

  return (
    <div className="row mx-0">
      <div className="col-12 px-0">
        <div className="row mx-0">
          <h6 className="ms-lg-2 fw-bolder fs-5">Previously Taken Tests</h6>
          <div className="col-lg-12 col-12 d-lg-flex flex-lg-wrap">
            {verifiedTests.length > 0 ? (
              verifiedTests.map((item, index) => (
                <div key={index} className="Assessment-card-manage">
                  <div className="card rounded m-lg-2 mb-2">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="py-0 fw-bold">{item.title}</p>
                        <div>
                          <button
                            className={`btn fs-6 ${item.isPassed ? "btn-outline-success" : "btn-outline-danger"}`}
                            disabled
                          >
                            {item.isPassed ? "Passed" : "Failed"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 p-2 text-center">
                <p>You are not verified in any test!</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentCardVerified;

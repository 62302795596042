import { useSelector } from "react-redux";
import QuestionRowCard from "./QuestionRowCard";

const QuestionHeroSection = () => {
  const { quizTestsQuestions } = useSelector(state => state.quizTest);
  return (
    <>
      <div className="row mx-0  questions-list">
        <div className="col-lg-12 col-sm-6 p pt-3 px-0 d-flex">
          <div className="col-lg-6 p-0 d-flex align-items-baseline">
            <input type="Checkbox" />
            <h5 className="mx-2">Multiple Choice Questions</h5>
            <span>Total score ()</span>
          </div>
          <div className="col-lg-6 p-0 d-flex align-items-baseline justify-content-end">
            <a href="">
              <i className="bi bi-filter-left"></i>
              <i className="bi bi-plus"></i>
            </a>
            <a>
              <i className="bi bi-three-dots mx-3"></i>
            </a>
          </div>
        </div>
        <div className="col-lg-12 col-sm-6 py-4 px-0">
          {quizTestsQuestions && quizTestsQuestions.map((item, index) => (
            <QuestionRowCard item={item} index={index} />
          ))}
        </div>
      </div>
    </>
  );
};

export default QuestionHeroSection;

import CreateQuestionModal from "./created_questions_list/CreateQuestionModal";
import React, { useState } from "react";
import { ReactComponent as NoData } from "../../../../../../assets/images/No_data_re_kwbl.svg";


const QuestionNodataCard = () => {
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <NoData className="no-data" />
      <h2>No Questions Available</h2>
      <div className="d-flex pt-3 justify-content-around">
        <div
          onClick={() => setOpenModal(!openModal)}
          className="btn btn-primary w-10"
        >
          Create New Question
        </div>
        Or
        <a href="">Create a New Question</a>
      </div>
      <CreateQuestionModal open={openModal} toggle={toggleModal} />
    </>
  );
};

export default QuestionNodataCard;
import { useParams } from "react-router-dom";
import QuizTestTakenHeading from "../QuizTestTakenHeading";
import CandidatesTables from "../Tables/CandidatesTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTestTakens } from "../../../../actions/operations";
import Loader from "../../../../../../shared_components/Loader";

const QuizTestTaken =({setActiveTab,activeTab})=>{
  const {id} = useParams();
  const {quizTestTakens,quizTestTakenLoading } = useSelector(state =>state.quizTest);
  const dispatch = useDispatch();
  useEffect(()=>{
     dispatch(getTestTakens(id));
  },[id]);

  return(
    <>
    {quizTestTakenLoading ? 
      ( <Loader />) 
      :
      (
        <div className="row mx-0 p-3 testTaken-section">
        {quizTestTakens && <div className="col-12 px-0">
          <QuizTestTakenHeading activeTab={activeTab} />
          <CandidatesTables />
        </div>
        }
      </div>
      )
    }
    </>
  )
};

export default QuizTestTaken;
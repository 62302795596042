import { useEffect, useState } from "react";
import QuestionEditor from "../Question/QuestionCreateEdit/QuestionEditor";
import { useDispatch, useSelector } from "react-redux";
import { EditQuizTest } from "../../../../actions/operations";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

const QuizTestInstruction = () => {
  const { currentQuizTest } = useSelector(state => state.quizTest);
  const [isEditable, setIsEditable] = useState(false);
  const [instructions, setInstructions] = useState('<p></p>');
  const dispatch = useDispatch();
  const { id } = useParams();
  const handleEditClick = () => {
    setIsEditable(true);
  };

  useEffect(() => {
    setInstructions(currentQuizTest?.instructions);
  }, [currentQuizTest]);

  const handleSaveClick = () => {
    setIsEditable(false);
    dispatch(EditQuizTest({ quizTest: id, instructions: instructions }));
    }

  return (
    <div className="row mx-0 mb-3 px-0">
      <div className="col-12 px-0 py-2 d-flex align-items-baseline head-test-instruction">
        <h5>Test Instructions</h5>
        {!isEditable ? (
          <button className="btn btn-link mx-2 p-0" onClick={handleEditClick}>
            Edit
          </button>
        ) : (
          <button className="btn btn-link mx-2 p-0" onClick={handleSaveClick}>
            Save
          </button>
        )}
      </div>
      <div className="col-12 p-0 test-instructions">
        {!isEditable ? (
          <div className="p-2">{currentQuizTest && currentQuizTest.instructions && parse(currentQuizTest?.instructions)}</div>
        ) : (
          currentQuizTest &&
          <QuestionEditor
            text={instructions}
            handleTextEditor={setInstructions}
          />
        )}
      </div>
    </div>
  );
};

export default QuizTestInstruction;

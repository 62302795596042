const TestReliability =()=>{
  return(
    <>
      <div className="col-12 p-3 mb-3 reliability-section">
        <h5>Reliability</h5>
        <p>A reliability text is a test in which two or more candidates with similar skills and knowledge receive similar score.</p>
        <p>A reliabilty Score is calculted for every test that is created and taken by at least 50 candidates.</p>
        <span className="d-flex my-4 align-items-center justify-content-evenly reliability-score">
          <i className="bi bi-shield-check "></i>
          <span>Reliability score</span>
          <span className="score">9.1/10</span>
        </span>
      </div>
    </>
  )
};

export default TestReliability;
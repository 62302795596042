import { useDispatch, useSelector } from "react-redux";
import { getQuizQuestionsList } from "../../../../actions";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const QuestionsLevelList = ({currentQuizTest}) => {

  const quizQuestions =currentQuizTest?.quizQuestions;

  const getQuestionsByLevel = (level) => {
    const questions = quizQuestions?.filter((ques) => ques.level === level) || [];
    const totalScore = questions.reduce((sum, ques) => sum + parseFloat(ques.maxScore || 0), 0);
    return { questions, totalScore };
  };
  
  const { questions: easyQuestions, totalScore: easyScore} = getQuestionsByLevel("easy");
  const { questions: mediumQuestions, totalScore: mediumScore} = getQuestionsByLevel("medium");
  const { questions: hardQuestions, totalScore: hardScore} = getQuestionsByLevel("hard");

  return (
    <div className="col-lg-12 px-0 mb-3 table-responsive question-list">
    {quizQuestions && 
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Question Type</th>
            <th scope="col">Difficulty Level</th>
            <th scope="col">Question</th>
            <th scope="col">Score</th>
          </tr>
        </thead>
        <tbody>
          {easyQuestions.length > 0 && (
            <tr>
              <td>Multiple Choice Questions</td>
              <td>easy ({easyQuestions.length})</td>
              <td>{easyQuestions.length}</td>
              <td>{easyScore}</td>
            </tr>
          )}
          {mediumQuestions.length > 0 && (
            <tr>
              <td>Multiple Choice Questions</td>
              <td>medium ({mediumQuestions.length})</td>
              <td>{mediumQuestions.length}</td>
              <td>{mediumScore}</td>
            </tr>
          )}
          {hardQuestions.length > 0 && (
            <tr>
              <td>Multiple Choice Questions</td>
              <td>hard ({hardQuestions.length})</td>
              <td>{hardQuestions.length}</td>
              <td>{hardScore}</td>
            </tr>
          )}
        </tbody>
      </table>
    }
    </div>
  );
};

export default QuestionsLevelList;

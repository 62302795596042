import ReportSkillPerformanceChart from "../Charts/ReportSkillPerformanceChart";

const SkillPerformance =() =>{
  return(
    <div className="col-12 mt-3 px-4 py-3 skill-performance">
      <div className="row">
        <div className="col-12">
          <h6>Skill-wise Performance</h6>
        </div>
        <div className="col-12">
         <ReportSkillPerformanceChart />
        </div>
      </div>
    </div>
  )
};

export default SkillPerformance;
const ObservationCard = () =>{
  return(
    <div className="col-lg-4 px-0 m-2">
      <div className="row mx-0 observation-card">
        <div className="col-12 p-3 d-flex heading-part">
          <i className="bi bi-check-circle mx-3 "></i>
          <span>Tabs Switched</span>
        </div>
        <div className="col-12 p-3 desc-part">
          <p>Did not exceeded the tab lemeint</p>
        </div>
      </div>
    </div>
  )
};

export default ObservationCard;
import { useState } from "react";
import QuestionEditor from "./QuestionEditor";

const QuestionEditForm = ({ setQuestion, setChoices, setCorrectChoice, choices, question, correctChoice }) => {
  const handleChoiceChange = (index, value) => {
    const newChoices = [...choices];
    newChoices[index] = value;
    setChoices(newChoices);
  };

  const handleCorrectChoiceChange = (index) => {
    setCorrectChoice(`option${index + 1}`);
  };

  return (
    <div className="col-12 p-3 mr-2 question-edit-form">
      <form>
        <div className="row">
          <h5>Problem Statement</h5>
          <div className="col-12">
            <QuestionEditor text={question} handleTextEditor={setQuestion} />
          </div>
        </div>
        <div className="row px-2">
          <div className="col-12 py-3 px-0">
            <h6 className="px-2 m-0">Choices</h6>
            <h6 className="p-2 m-0">
              Enter the option and mark the radio button corresponding to the correct option
            </h6>
          </div>
          <div className="col-12 d-flex p-0">
            <div className="col-4 d-flex text-align-baseline">
              <input type="checkbox" />
              <span className="m-1">Allow Multiple Answers</span>
            </div>
            <div className="col-4 d-flex text-align-baseline">
              <input type="checkbox" />
              <span className="m-1">Allow partial Scoring</span>
            </div>
            <div className="col-4 d-flex text-align-baseline">
              <input type="checkbox" />
              <span className="m-1">Allow Shuffling Options</span>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end p-2">
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" role="switch" id={`flexSwitchCheckDefault`} />
              <label className="form-check-label" htmlFor={`flexSwitchCheckDefault`}>
                Rich text
              </label>
            </div>
          </div>
          <div>
            {choices.map((choice, index) => (
              <div className="col-12 p-1 d-flex justify-content-evenly" key={index}>
                <input
                  type="radio"
                  name="correctChoice"
                  value={index}
                  checked={correctChoice === `option${index + 1}`}
                  onChange={() => handleCorrectChoiceChange(index)}
                />
                <input
                  type="text"
                  className="mx-1"
                  name={`option${index + 1}`}
                  value={choice}
                  onChange={(e) => handleChoiceChange(index, e.target.value)}
                  placeholder={`option ${index + 1}`}
                />
                <button className="cross-button" type="button">
                  X
                </button>
              </div>
            ))}
          </div>

        </div>
      </form>
    </div>
  );
};

export default QuestionEditForm;

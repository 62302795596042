const QuestionEditSidebar = ({
  maxScore,
  setMaxScore,
  negativeScore,
  setNegativeScore,
  difficultyLevel,
  setdifficultyLevel,
  isTimedQuestion,
  setisTimedQuestion,
  questionTag,
  setQuestionTag,
  duration,
  setDuration,
}) => {
  const handleDifficultyChange = (event) => {
    setdifficultyLevel(event.target.value);
  };
  return (
    <>
      <div className="row px-2 py-3 mx-0 edit-sidebar ">
        <form>
          <div className="col-12">
            <h6>Difficulty Level</h6>
            <div className="d-flex flex-column">
              <label className="d-flex  px-2 align-items-center mb-2">
                <input
                  type="radio"
                  name="difficulty"
                  value="easy"
                  className="me-2"
                  Checked={difficultyLevel === "easy"}
                  onChange={handleDifficultyChange}
                />
                <span>Easy</span>
              </label>
              <label className="d-flex px-2 align-items-center mb-2">
                <input
                  type="radio"
                  name="difficulty"
                  value="medium"
                  className="me-2"
                  checked={difficultyLevel === "medium"}
                  onChange={handleDifficultyChange}
                />
                <span>Medium</span>
              </label>
              <label className="d-flex px-2 align-items-center mb-2">
                <input
                  type="radio"
                  name="difficulty"
                  value="hard"
                  className="me-2"
                  checked={difficultyLevel === "hard"}
                  onChange={handleDifficultyChange}
                />
                <span>Hard</span>
              </label>
            </div>
          </div>
          <div className="col-12 ">
            <h6>Maximum Score</h6>
            <input
              type="text"
              value={maxScore}
              className="score"
              onChange={(e) => {
                setMaxScore(e.target.value);
              }}
            />
          </div>
          <div className="col-12 ">
            <h6>Negative Score</h6>
            <input
              type="text"
              value={negativeScore}
              className="score "
              onChange={(e) => {
                setNegativeScore(e.target.value);
              }}
            />
            <p>
              This Score is deducted from Total Scores if the anser is incorrect
            </p>
          </div>
          <div className="col-12 ">
            <input
              type="checkbox"
              checked={isTimedQuestion === true}
              onChange={() => setisTimedQuestion((prev) => !prev)}
            />{" "}
            <span className="py-4">Make this a timed question</span>
            {isTimedQuestion && (
              <input
                type="text"
                name="duration"
                value={duration}
                placeholder="Time duration (in min)"
                onChange={(e) => setDuration(e.target.value)}
              />
            )}
          </div>
          <div className="col-12 ">
            <h6>Tags</h6>
            <input
              type="text"
              name="tags"
              value={questionTag}
              className="tag-field"
              onChange={(e) => {
                setQuestionTag(e.target.value);
              }}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default QuestionEditSidebar;

import { useState } from "react";
import parse from "html-react-parser";

const ReportQuestionDetailsCard = ({ item, index }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const {
    title = "",
    option1,
    option2,
    option3,
    option4,
    correct_answer = "",
  } = item.question || "{}";

  const sanitizedTitle = title.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  const collapseId = `${sanitizedTitle}-${index}`;

  const handleToggleClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getCorrectAnswer = () => {
    const options = {
      option1: option1,
      option2: option2,
      option3: option3,
      option4: option4
    };

    return options[correct_answer];
  };

  return (
    <div className="col-12 px-0 questions-detail-toggle">
      <div className="col-12 p-2 d-flex align-items-center">
        <div className="col-6 d-flex justify-content-start">
          <span
            className="toggle-button px-2"
            data-bs-toggle="collapse"
            data-bs-target={`#${collapseId}`}
            aria-expanded={!isCollapsed}
            aria-controls={collapseId}
            onClick={handleToggleClick}
          >
            {isCollapsed ? (
              <i className="bi bi-chevron-right"></i>
            ) : (
              <i className="bi bi-chevron-down"></i>
            )}
          </span>
          <p className="px-5 m-0">{title && parse(title)}</p>
        </div>
        <div className="col-6 px-2 d-flex justify-content-end">
          <p className="px-5 m-0">
            {getCorrectAnswer() === item.selectedAnswer ? <i className="bi bi-check-circle h5"></i>
              : <i className="bi bi-x-circle h5"></i>}
          </p>
          <p className="px-4 m-0">2/2</p>
        </div>
      </div>
      <div className="col-12 collapse" id={collapseId}>
        <div className="row mx-0 px-5">
          <div className="col-6 px-5">
            <h6>Options</h6>
            <ol type="A">
              <div className="d-flex">
                <li>{option1}</li>{" "}
                {correct_answer === "option1" ? (
                  <span className="correct-field">Correct</span>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex">
                <li>{option2}</li>{" "}
                {correct_answer === "option2" ? (
                  <span className="correct-field">Correct</span>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex">
                <li>{option3}</li>{" "}
                {correct_answer === "option3" ? (
                  <span className="correct-field">Correct</span>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex">
                <li>{option4}</li>{" "}
                {correct_answer === "option4" ? (
                  <span className="correct-field">Correct</span>
                ) : (
                  ""
                )}
              </div>
            </ol>
          </div>
          <div className="col-6 px-5">
            <h6>Partial Scoring</h6>
            <p>No</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportQuestionDetailsCard;

import { IoShieldCheckmark } from "react-icons/io5"
import { HiQuestionMarkCircle } from "react-icons/hi";
import { SiTarget } from "react-icons/si";
import { BsPieChartFill } from "react-icons/bs";
import { FaBrain } from "react-icons/fa";
import img from "../../../../assets/images/how-it-works-works-png-removebg-preview.png"

const QuizBoardLandingHowWork = () => {
  return (
    <>
      <div className="row mx-0 pt-5 QuizBoardLandingHowWork">
        <div className="col-12 px-0">
          <div className="">
            <h3 className="text-center">How It Works</h3>
            <div className="row mx-0 align-items-center justify-content-center">
              <div className="col-lg-6 px-0">
              <div className="justify-content-center align-items-center gap-5 px-lg-5 py-5 text-center">
               <div className="QuizBoardLandingHowWork-Card">
                <span><IoShieldCheckmark /></span>
                <h5 className="lh-base">Sign Up</h5>
                <p className="lh-base">Create an account or log in to get started with personalized quizzes.</p>
               </div>
               <div className="QuizBoardLandingHowWork-Card">
                <span><HiQuestionMarkCircle /></span>
                <h5 className="lh-base">Pick a Quiz</h5>
                <p className="lh-base">Choose from a variety of topics, or let us surprise you with random quizzes.</p>
               </div>
               <div className="QuizBoardLandingHowWork-Card">
                <span><FaBrain /></span>
                <h5 className="lh-base">Test Your Skills</h5>
                <p className="lh-base">Answer questions, track your scores, and aim for high ranks on the leaderboard.</p>
               </div>
               <div className="QuizBoardLandingHowWork-Card">
                <span><BsPieChartFill /></span>
                <h5 className="lh-base">Get Results</h5>
                <p className="lh-base">View your detailed performance report and strive to improve each time.</p>
               </div>
            </div>
              </div>
              <div className="col-lg-6 px-0 ">
                <div className="">
                  <img src={img} alt="img" className="QuizBoardLandingHowWork-img"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default QuizBoardLandingHowWork
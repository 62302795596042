import TestAnalyticsHeader from "./TestAnalyticsHeader";
import TestReliability from "./TestReliability";
import CandidateHiringFunnel from "./CandidateHiringFunnel";
import TestPerformanceMetric from "./TestPerformanceMetric";
import CandidatesTestScoreDistribution from "./TestScoreDistribution";

const QuizTestAnalytics = ({ setActiveTab, activeTab }) => {
  return (
    <>
      <div className="row mx-0 p-3 test-analytics-section ">
        <TestAnalyticsHeader activeTab={activeTab} />
        <TestReliability />
        <CandidateHiringFunnel />
        <TestPerformanceMetric />
        <CandidatesTestScoreDistribution />
      </div>
    </>
  );
};

export default QuizTestAnalytics;

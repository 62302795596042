const QuizTestTakenHeading = ({activeTab}) => {
  const capitalize = (str) => {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <>
      <div className="row mx-0 mb-3 header-testtaken">
        <div className="col-lg-12 px-0 d-flex justify-content-between Header-Test-Taken-Child">
          <div className="col-sm-6 d-flex align-items-baseline">
            <h5>{capitalize(activeTab)}</h5>
            <i className="bi bi-exclamation-circle m-2"></i>
          </div>
          <div className="col-sm-6  d-flex justify-content-end align-items-baseline Header-Test-Taken-Child-A">
            <span>
              <a href="" className="m-2">Reset Test</a>
            </span>
            <span>
              <a href="" className="m-4">Extend Time</a>
            </span>
            <span className="m-2">
              <select  id="accessSelect">
                <option selected>Request report</option>
                <option value="1">*</option>
                <option value="2">*</option>
              </select>
            </span>
          </div>
        </div>
      </div>
    </>
  )
};

export default QuizTestTakenHeading;

import React, { useEffect, useState } from "react";
import QuizTestOverview from "./Overview";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QuizTestQuestions from "./Question";
import QuizTestHeader from "./QuizTestHeader";
import QuizTestEmail from "./EmailTab/QuizTestEmail";
import QuizTestTaken from "./TestTaken/QuizTestTaken";
import QuizTestInvited from "./InvitedTab/QuizTestInvited";
import QuizReviewPending from "./ReviewPending/QuizReviewPending";
import CandidatesFeedback from "./CandidatesFeedback/CandidatesFeedback";
import QuizTestAnalytics from "./TestAnalytics/QuizTestAnalytics";
import TestQuestionAnalytics from "./QuestionAnalytics/TestQuestionAnalytics";
import QuizTestSideNavbar from "./QuizTestSideNavbar";
import QuizTestSideNavbarDrawer from "./Question/Drawers/QuizTestSideNavbarDrawer";
import { getQuizTestDetails } from "../../../actions";

const QuizTestDashboard = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { quizTestDetails } = useSelector(({ quizTest }) => quizTest) || {};

  const [activeTab, setActiveTab] = useState("overview");

  useEffect(() => {
    dispatch(getQuizTestDetails({ quizTest: id, mode: 'creator' }));
  }, [id, quizTestDetails])


  const renderActiveComponent = (activeTab) => {
    const componentDictionary = {
      overview: <QuizTestOverview setActiveTab={setActiveTab} activeTab={activeTab} />,
      questions: <QuizTestQuestions setActiveTab={setActiveTab} activeTab={activeTab} />,
      emailTab: <QuizTestEmail setActiveTab={setActiveTab} activeTab={activeTab} />,
      testTaken: <QuizTestTaken setActiveTab={setActiveTab} activeTab={activeTab} />,
      reviewPending: <QuizReviewPending setActiveTab={setActiveTab} activeTab={activeTab} />,
      invited: <QuizTestInvited setActiveTab={setActiveTab} activeTab={activeTab} />,
      CandidateFeedback: <CandidatesFeedback setActiveTab={setActiveTab} activeTab={activeTab} />,
      TestAnalytics: <QuizTestAnalytics setActiveTab={setActiveTab} activeTab={activeTab} />,
      QuestionAnalytics: <TestQuestionAnalytics setActiveTab={setActiveTab} activeTab={activeTab} />
    };

    return componentDictionary[activeTab];
  };

  return (
    <>
      <div className="row mx-0 mt-5">
        <QuizTestHeader />
        <div className="col-12 px-0">
          <div className="row mx-0 d-flex">
            <div className="col-lg-2 px-0 ">
              {/* <div className="quiztestsideNAvbar"><QuizTestSideNavbar setActiveTab={setActiveTab} activeTab={activeTab} /></div> */}
              <QuizTestSideNavbarDrawer setActiveTab={setActiveTab} activeTab={activeTab} />
            </div>
            <div className="col-lg-10 px-4 quiz-test-body">
              {renderActiveComponent(activeTab)}
            </div>
          </div>
        </div>

      </div>

    </>
  )
};

export default QuizTestDashboard;
import * as constants from "../constants";

// Define the handler function 
const setTestUserHandler = (state, action) => ({
  ...state,
  testUser: action.payload,
});

// Define the initial state
const initialState = {
  testUser: null,
};

// Define the handlers mapping
const testUserHandlers = {
  [constants.GET_TEST_USER]: setTestUserHandler,
};

// Define the reducer function
export const testUserReducer = (state = initialState, action) => {
  // Get the handler function based on the action type
  const handler = testUserHandlers[action.type] || ((state) => state);
  // Call the handler function
  return handler(state, action);
};

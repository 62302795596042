const QuestionEditFooter = ({ setIsEditDrawer,questiontype,HandleAddFormData,HandleEditFormData}) => {
  
  return (
    <>
      <div className="col-12 py-2 d-lg-flex justify-content-end edit-footer ">
      {questiontype.length > 0 ?
      <>
      <button className="publish-btn" onClick={HandleAddFormData}> Create</button>
      </> :
      <>
      <button onClick={()=>{setIsEditDrawer(false)}} className="discard-btn" > Discard Changes</button>
      <button  className="publish-btn" onClick={HandleEditFormData}> Publish Changes </button>
      </>
      }
      </div>
    </>
  )
};

export default QuestionEditFooter;

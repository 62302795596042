import SettingsSection from "./SettingsSection";
import OtherDetailsSection from "./OtherDetailsSection";

const QuizSettingandOtherSetting = () => {
  return (
    <>
      <div className="col-12 px-0 mb-4">
        <div className="row mx-0 ">
          <div className="col-lg-8  setting-section ">
            <SettingsSection />
          </div>
          <div className="col-lg-4  OtherDetailsSection">
            <OtherDetailsSection/>
          </div>
        </div>
      </div>
    </>
  )
};

export default QuizSettingandOtherSetting;
import ObservationCard from "./ObservationCard";

const KeyObservations = () => {
  return (
    <div className="col-12 p-3 mt-3 key-observations">
      <div className="row mx-0">
        <div className="col-12">
          <h6>Key Observations</h6>
        </div>
        <div className="col-lg-12 px-0 d-lg-flex flex-wrap ">
          <ObservationCard />
          <ObservationCard />
          <ObservationCard />
        </div>
      </div>
    </div>
  );
};

export default KeyObservations;

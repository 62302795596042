import { useEffect, useState } from "react";
import QuestionEditor from "../Question/QuestionCreateEdit/QuestionEditor";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EditQuizTest } from "../../../../actions";
import parse from 'html-react-parser';



const QuizTestDescriptionSection = () => {
  const {currentQuizTest} = useSelector(state=>state.quizTest);
  const [testDescription, setTestDescription ] = useState('<p></p>');
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useDispatch();;
  const {id} = useParams();

  useEffect(()=>{
    setTestDescription(currentQuizTest?.description);
  },[currentQuizTest]);

  const handleAddClick = (e) => {
    e.preventDefault();
    setIsEditable(true);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    setIsEditable(false);
    const data={"quizTest":id,"description":testDescription};
    dispatch(EditQuizTest(data))
  };

  return (
    <div className="row mx-0 mb-4 px-0">
      <div className="col-12 px-0 d-flex align-items-baseline">
        <span>
          <h5>Test Description</h5>
        </span>
        <span>
          {isEditable ? (
            <a href="" className="m-2" onClick={handleSaveClick}>
              Save
            </a>
          ) : (
            <a href="" className="m-2" onClick={handleAddClick}>
              Add
            </a>
          )}
        </span>
      </div>
      {isEditable && (
        <div className="col-12 py-4">
          <QuestionEditor
            text = {testDescription} 
            handleTextEditor = {setTestDescription} 
          />
        </div>
      )}
      {!isEditable && (
        <div className="col-12 py-4 test-description">{testDescription && parse(testDescription)}</div>
      )}
    </div>
  );
};

export default QuizTestDescriptionSection;

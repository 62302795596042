import CandidatesInvitedTable from "../Tables/CandidatesInvitedTable";
const QuizTestInvited=({setActiveTab,activeTab})=> {
  return(
    <>
    <div className="row mx-0 p-3 invited-section">
          <div className="col-12 px-0 pb-3 invited-section-details">
            <h5 className="py-2">Invited</h5>
            <div className="d-flex align-items-baseline justify-content-between ">
              <p>1005 Candidates invited to take test | 902 students have not taken test yet</p>
              <p><a href="">Download Invites(.xls)</a></p>
            </div>
          </div>
          <div className="col-12 px-0">
            <CandidatesInvitedTable />
          </div>
        </div>
    </>
  )
};

export default QuizTestInvited;
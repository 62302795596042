import { Link } from "react-router-dom";
import { Dropdown, Space, Menu, Modal } from "antd";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { deleteQuizTest, getQuizTestsList } from "../../actions";
import { useDispatch } from "react-redux";
import { useState } from "react";

const QuizTestCard = ({
  item,
  index,
  disable = false,
  certificateUrl,
  prev = false,
  ispassed,
}) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const showDeleteConfirm = (id) => {
    setSelectedItemId(id);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(deleteQuizTest(selectedItemId)).then((res) => {
      dispatch(getQuizTestsList());
      setIsModalVisible(false);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item
        danger="true"
        key="delete"
        onClick={() => showDeleteConfirm(item._id)}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="col-lg-10 col-sm-12" key={index}>
        <div className="row mx-0 card p-4">
          <div className="col-12 px-0 my-1 text-left color-dark d-flex justify-content-between align-items-baseline">
            {disable ? (
              <>
              <h6>{item.quizTitle}</h6>
              {prev &&
                prev === true &&
                (ispassed ? (
                  <span className="border border-success border-2 text-success p-2 rounded-3">
                    Passed
                  </span>
                ) : (
                  <span className="border border-danger border-2 text-danger p-2 rounded-3">
                    Failed
                  </span>
                ))}
              </>
            ) : (
              <Link to={`/quiz/test/${item._id}`}>
                <h6>{item.quizTitle}</h6>
              </Link>
            )}

            {/* <Dropdown overlay={menu} trigger={["click"]}>
              <Space>
                <PiDotsThreeOutlineVerticalFill />
              </Space>
            </Dropdown> */}
          </div>
          {prev ? (
            " "
          ) : (
            <div className="col-12 my-1 d-lg-flex px-0 test-card">
              <span className="pr-3">
                <i
                  className={
                    item.access === "public" || item.access === "Public"
                      ? "bi bi-globe"
                      : "bi bi-envelope"
                  }
                ></i>{" "}
                {item.access} <span className="px-3">|</span>
              </span>
              <span className="px-lg-3">
                <i className="bi bi-clock"></i> {item.duration} min{" "}
                <span className="px-3">|</span>
              </span>
              <span className="px-lg-3">
                <i className="bi bi-calendar4"></i> {item.startDate} -{" "}
                {item.endDate}
              </span>
            </div>
          )}

          <div className="col-12 px-0 d-flex justify-content-between">
            <div className="col-lg-6 d-flex  px-0">
              {certificateUrl && (
                <a href={certificateUrl || "#"}>View candidate Report</a>
              )}
              
            </div>
            
            <div className="col-lg-6 text-right">
              {item.previewLink ? (
                <a href="#">
                  <i className="bi bi-eye"></i> Preview Test
                </a>
              ) : null}
              {item.addinvite ? (
                <a href="#">
                  <i className="bi bi-person-plus"></i> Invite Candidates
                </a>
              ) : null}{" "}
              {item.CanArchieve ? (
                <a href="#">
                  <i className="bi bi-archive"></i> Archive
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        title="Confirm Deletion"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this quiz test?</p>
      </Modal> */}
    </>
  );
};

export default QuizTestCard;

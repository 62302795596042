import { MdBookmarkBorder, MdBookmarkAdded } from "react-icons/md";
// import img from "../../../assets/images/img/screenshot.png";
import parse from "html-react-parser";


const QuizBodyQuestion = ({ answers, handleFlagQuestion, quizTestsQuestions, currentPage }) => {

  const questionIndex = Math.max(0, Math.min(currentPage, quizTestsQuestions.length - 1));

  const isFlagged = (questionId) => {
    return answers.some(answer => answer.questionId === questionId && answer.flag);
  };

  const questionId = quizTestsQuestions && quizTestsQuestions?.[currentPage] && quizTestsQuestions?.[currentPage]?._id
  return (
    <>
      <section>
        <div className="row mx-0">
          <div className="col-12 my-0 pt-3 mb-lg-5 pb-lg-5  pb-sm-5">
            <div className="d-flex justify-content-between QuizQuestion-section">
              <div className="QuizQuestion fs-6"> {`Question ${questionIndex + 1}`} </div>
              <div onClick={() => handleFlagQuestion(questionId)} className="QuizQuestionData">
                <span className="fs-6">{isFlagged(questionId) ? <MdBookmarkAdded /> : <MdBookmarkBorder />}</span>

                <span className={`ps-2 fs-6 ${isFlagged() && 'text-primary'}`}>Revisit Later</span>
              </div>
            </div>
            <div className=" pt-3 fs-4">
              <p className="text-start">
                {quizTestsQuestions && quizTestsQuestions?.[currentPage] && parse(quizTestsQuestions?.[currentPage]?.title)}

              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )

}
export default QuizBodyQuestion;
import { useDispatch, useSelector } from "react-redux";
import CreateQuizModal from "../../contributor_home/create_quiz_header/CreateQuizModal";
import { useState } from "react";
import OtherDetailsForm from "./OtherDetailsForm";
import { EditQuizTest, getQuizTestsList } from "../../../../actions";

const OtherDetailsSection = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isEditable,setIsEditable] = useState(false);
  const dispatch = useDispatch();

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleOtherDeatils =(formData)=>{
    dispatch(EditQuizTest(formData));
  }
  
  const OtherDetails = {
    "test_access": "on", "test_link": "https://goggle.com", "practice_test_link": "http://facebook.com",  "_id": "12345"
  }

  return (
    <>
      <div className="col-12 p-3  other-section">
        <div className="row mx-0">
          <div className="col-lg-12 d-flex align-items-baseline">
            <h5>OtherDetails</h5>
            <a className="px-2" onClick={() => setOpenModal(true)}> Edit</a>
          </div>
          <OtherDetailsForm isEditable={isEditable} OtherDetails={OtherDetails} />
        </div>
      </div>
      <CreateQuizModal open={openModal} toggle={toggleModal} onSubmit={handleOtherDeatils}>
        <OtherDetailsForm  isEditable={openModal}  OtherDetails={OtherDetails} />
      </CreateQuizModal>
    </>
  );
};

export default OtherDetailsSection;

const ReportCommentSection =() =>{
  return(
    <div className="col-12 px-4 py-3 my-3 report-comment">
      <div className="row mx-0">
        <div className="col-12 px-0">
          <h6>Comments</h6>
        </div>
        <div className="row mx-0 px-0">
          <div className="col-12 px-0 ">
            <textarea rows="2" name="comment" placeholder="Start Discussion..." className="p-2"></textarea>
          </div>
          <div className="col-12 px-0 py-2 d-flex justify-content-end align-items-baseline border-bottom">
            <a className="px-3 text-decoration-none cancel-button">Cancel</a>
            <button className="post-button">Post</button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ReportCommentSection;
import { useState } from "react";
import QuestionViewDrawer from "./Drawers/QuestionViewDrawer";
import { useDispatch } from "react-redux";
import { deleteQuizQuestion, getQuizQuestionsList } from "../../../../actions/operations";
import { Dropdown, Space, Menu, Modal } from 'antd'; 
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';


const QuestionRowCard = ({ item, index }) => {
  const [open, setOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [selectedItemId, setSelectedItemId] = useState(null); 
  const dispatch = useDispatch();
  const { id } = useParams();

  const showDeleteConfirm = (quizid) => {
    setSelectedItemId(quizid);
    setIsModalVisible(true);
  };

  const handleDelete = () => {
    dispatch(deleteQuizQuestion(selectedItemId)).then((res) => {
      if (res.success) {
        dispatch(getQuizQuestionsList(id));
        setIsModalVisible(false); 
      }
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false); 
  };

  const handleView = () => {
    setOpen(true);
  };

  const menu = (
    <Menu>
      <Menu.Item key="view" onClick={handleView}>
        View
      </Menu.Item>
      <Menu.Item className="delete-menu" danger="true" key="delete" onClick={() => showDeleteConfirm(item._id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="row mx-0 p-3 text-left question-card">
        <div className="col-12 d-flex align-items-baseline justify-content-between pb-4">
          <div>
            <h6 className="mr-2">0{index + 1}</h6>
            {item && item?.title && parse(item?.title)}
          </div>
          <div>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Space>
                <PiDotsThreeOutlineVerticalFill />
              </Space>
            </Dropdown>
          </div>
        </div>
        <div className="col-12 d-flex pb-2 justify-content-between">
          <button className="qlevel-btn ml-5">{item?.level}</button>
          <button className="score-btn">Score: {item?.maxScore}</button>
        </div>
      </div>
      <QuestionViewDrawer open={open} setOpen={setOpen} questionId={item._id} />

      <Modal
        title="Confirm Deletion"
        visible={isModalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this question?</p>
      </Modal>
    </>
  );
};

export default QuestionRowCard;

import { useSelector } from "react-redux";
import ReportQuestionDetailsCard from "./ReportQuestionDetailsCard";

const DetailedSubmissionReport = () => {
  const { candidateReportReviewDetails } = useSelector(
    (state) => state.quizTest
  );

  const { responses } = candidateReportReviewDetails;
  return (
    responses && (
      <div className="col-12 px-4 py-3 mt-3 detailed-submission">
        <div className="row mx-0">
          <div className="col-lg-12 px-0">
            <h6>Detailed Submission Report</h6>
          </div>
          <div className="col-lg-12 px-0 questions-attempt-table table-responsive">
            <div className="row mx-0 pt-2">
              <div className="col-lg-6 px-0 text-left">
                <p className="m-1 heading-para">Multiple Choice Questions</p>
              </div>
              <div className="col-lg-6 px-0 d-lg-flex justify-content-lg-end">
                <p className="m-1 heading-para">
                  Questions Attempted: 5 of {responses.length}
                </p>
              </div>
            </div>
            <div className="row mx-0 border">
              <div className="col-lg-12 py-2 d-lg-flex justify-content-center align-items-center detail-table-header table-responsive">
                <div className="col-6 px-lg-3 d-lg-flex justify-content-start">
                  <h6 className="m-0">#</h6>
                  <h6 className="px-lg-5 m-0">Questions()</h6>
                </div>
                <div className="col-lg-6 px-3 d-flex justify-content-end">
                  <h6 className="px-5 m-0">Result</h6>
                  <h6 className="m-0">Score()</h6>
                </div>
              </div>
              {responses.map((item, index) => {
                return <ReportQuestionDetailsCard key={index} item={item} />;
              })}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DetailedSubmissionReport;

import QuestionViewDrawer from "./Drawers/QuestionViewDrawer.jsx";
import { useState } from "react";

const QuestionTypesSidebar = () => {
  const [questiontype,setQuestionType] = useState("Multiple choice")
  const [open ,setOpen] = useState(false);
  const HandleClick =(e)=>{
    setQuestionType(e.target.innerText);
    setOpen(true);
  }
  return(
    <>
      <div className="row px-3 mx-0 q-type-sidebar ">
        <div className="col-12 pt-3 pb-2">
          <h4>Select Question Type</h4>
        </div>
        <div className="col-12">
          <div className="col-12 d-flex align-items-baseline pb-1">
            <h5>General</h5><i className="bi bi-exclamation-circle m-2"></i>
          </div>
          <div className="col-12 d-flex flex-column">
            <span onClick={(e)=>{HandleClick(e)}} >Multiple choice</span>
            <span onClick={(e)=>{HandleClick(e)}}>Subjective</span>
            <span onClick={(e)=>{HandleClick(e)}}>Diagram</span>
          </div>
        </div>
        <div className="col-12 ">
          <div className="col-lg-12 d-flex align-items-baseline py-1">
            <h5>Coding</h5><i className="bi bi-exclamation-circle m-2"></i>
          </div>
          <div className="col-12 d-flex flex-column">
            <span onClick={(e)=>{HandleClick(e)}}>Programming</span>
            <span onClick={(e)=>{HandleClick(e)}}>Data Science</span>
            <span onClick={(e)=>{HandleClick(e)}}>Fornt End</span>
            <span onClick={(e)=>{HandleClick(e)}}>SQL</span>
            <span onClick={(e)=>{HandleClick(e)}}>Machine Learning</span>
          </div>
        </div>
        <div className="col-12 ">
          <div className="col-12 d-flex align-items-baseline py-1">
            <h5>Product</h5><i className="bi bi-exclamation-circle m-2"></i>
          </div>
          <div className="col-12 d-flex flex-column">
            <span onClick={(e)=>{HandleClick(e)}}>Full Stack</span>
            <span onClick={(e)=>{HandleClick(e)}}>Java</span>
            <span onClick={(e)=>{HandleClick(e)}}>C#</span>
            <span onClick={(e)=>{HandleClick(e)}}>Python</span>
            <span onClick={(e)=>{HandleClick(e)}}>File Upload</span>
            <span onClick={(e)=>{HandleClick(e)}}>Deveops</span>
          </div>
        </div>
        <QuestionViewDrawer open={open} setOpen={setOpen} questiontype={questiontype} />
      </div>
    </>
  )
};

export default QuestionTypesSidebar;

import React, { useEffect } from "react";
// import CreateQuestionForm from './create_question_form/CreateQuestionForm'
import CreatedQuestionsList from "./created_questions_list/CreatedQuestionsList";
import { useDispatch, useSelector } from "react-redux";
import QuestionsHeader from "./QuestionsHeader";
import "../../../../../../assets/scss/quiz_Dashboard/quiz_test_question.scss";
import { getQuizQuestionsList } from "../../../../actions/operations.js";
import { useParams } from "react-router-dom";
import Loader from "../../../../../../shared_components/Loader.jsx";

const QuizTestQuestions = ({ setActiveTab, activeTab }) => {
  const { quizTestDataLoading } = useSelector((state) => state.quizTest);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuizQuestionsList(id));
  }, [id]);

  return (
    <>
      <div className="row mx-0 p-3 ">
        {quizTestDataLoading ? (
          <Loader />
        ) : (
          <>
            <QuestionsHeader />
            <CreatedQuestionsList />
          </>
        )}
      </div>
    </>
  );
};

export default QuizTestQuestions;

import parse from 'html-react-parser';

const QuestionViewHero = ({ quizQuestionDetails }) => {
  return (
    <>
    {quizQuestionDetails &&
      <div className="col-7 p-4 text-left hero-section">
        <h5>Problem Statement</h5>
        <h6>{quizQuestionDetails && quizQuestionDetails?.title && parse(quizQuestionDetails?.title)}</h6>
        <h3>Choices</h3>
        <ul>
          <div className="d-flex">
            <li>{quizQuestionDetails?.option1}</li>{" "}
            {quizQuestionDetails?.correct_answer === "option1" ? (
              <span className="correct-field">Correct</span>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex">
            <li>{quizQuestionDetails?.option2}</li>{" "}
            {quizQuestionDetails?.correct_answer === "option2" ? (
              <span className="correct-field">Correct</span>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex">
            <li>{quizQuestionDetails?.option3}</li>{" "}
            {quizQuestionDetails?.correct_answer === "option3" ? (
              <span className="correct-field">Correct</span>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex">
            <li>{quizQuestionDetails?.option4}</li>{" "}
            {quizQuestionDetails?.correct_answer === "option4" ? (
              <span className="correct-field">Correct</span>
            ) : (
              ""
            )}
          </div>
        </ul>
      </div>}
    </>
  );
};

export default QuestionViewHero;

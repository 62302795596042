
const QuizTestSideNavbar = ({ setActiveTab, activeTab}) => {
  return (
    <>
      <div className="py-lg-4 text-left quiz-test-sidenav ">
        <h5>Candidates</h5>
        <ul>
          <li className={activeTab === "testTaken" ? "active-tab" : ""} onClick={() => setActiveTab("testTaken")}>Test Taken (0)</li>
          <li className={activeTab === "reviewPending" ? "active-tab" : ""} onClick={() => setActiveTab("reviewPending")}>Review Pending(0)</li>
          <li className={activeTab === "invited" ? "active-tab" : ""} onClick={() => setActiveTab("invited")}>Invited (0)</li>
        </ul>
        <h5>Analytics</h5>
        <ul>
          <li className={activeTab === "TestAnalytics" ? "active-tab" : ""} onClick={() => setActiveTab("TestAnalytics")}>Test Analytics</li>
          <li className={activeTab === "QuestionAnalytics" ? "active-tab" : ""} onClick={() => setActiveTab("QuestionAnalytics")}>Question Analytics</li>
          <li className={activeTab === "CandidateFeedback" ? "active-tab" : ""} onClick={() => setActiveTab("CandidateFeedback")}>Candidates Feedback</li>
        </ul>
        <h5>Test Details</h5>
        <ul>
          <li className={activeTab === "overview" ? "active-tab" : ""} onClick={() => setActiveTab("overview")}>
            Overview
          </li>
          <li className={activeTab === "questions" ? "active-tab" : ""} onClick={() => setActiveTab("questions")}>
            Questions
          </li>
          <li  className={activeTab === "emailTab" ? "active-tab" : ""} onClick={() => setActiveTab("emailTab")}>
            Email templates
          </li>
        </ul>
      </div>
    </>
  );
};

export default QuizTestSideNavbar;


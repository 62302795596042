import { useSelector } from "react-redux";
import QuizBoardCards from "./QuizBoardCards.jsx";
import image from "../../../../assets/images/study.jpg";

const QuizBoardHome = () => {
  const testUser = JSON.parse(localStorage.getItem("userDetails"));
  return (
    <>
      <div className="container-fluid px-0 mt-0 quiz-board">
        {/* <div className="row px-0 d-flex mt-3 p-4 intro-part align-items-center">
          <div className="col-6 text-center">
            {testUser && 
              <div className="px-5">
                <h2 className="px-4">Welcome {testUser.data.firstName} {testUser.data.lastName}</h2>
                <p className="px-4 mx-1 pt-2 ">Ready to Kick Start your Career With Us </p>
                <h6>All the Very Best</h6>
              </div>
              }
          </div>
          <div className="col-6 text-center">
            <img src={image} alt="into-img" width={350} height={300} />
          </div> 
        </div> */}
        <div className="row px-0 pt-5 quiz-main-section">
          <QuizBoardCards />
        </div>
      </div>
    </>
  )
};

export default QuizBoardHome;

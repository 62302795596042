import React, { useEffect, useState } from "react";
import { Button, Drawer } from "antd";
import QuestionView from "../QuestionView";
import QuestionCreateEdit from "../QuestionCreateEdit/QuestionCreateEdit";
import "../../../../../../../assets/scss/quiz_Dashboard/questionViewDrawer.scss";


const QuestionViewDrawer = ({ open, setOpen, questiontype = "" ,questionId="" }) => {

  const [isEditDrawer, setIsEditDrawer] = useState(false);

  const handleClose =()=>{
    setOpen(false)
    setIsEditDrawer(false)
  }

  return (
    <>
      <Drawer
        destroyOnClose
        placement="right"
        open={open}
        onClose={() => handleClose()}
        width={"80%"}
    
        className="custom-drawer  table-responsive-sm questionViewDrawer"
        >
        {questiontype.length === 0 ?
          <div className="drawer-header">
          <Button className="close-button" onClick={() => setOpen(false)}>
            X
          </Button>
          </div> : ""}
        {questiontype.length > 0 ? <QuestionCreateEdit setIsEditDrawer={setIsEditDrawer} questiontype={questiontype}/>  : null}
        {questiontype.length === 0 ? (
          isEditDrawer ? (
            <QuestionCreateEdit setIsEditDrawer={setIsEditDrawer} />
          ) : (
            <QuestionView setIsEditDrawer={setIsEditDrawer} questionId={questionId} />
          )
        ) : null}
      </Drawer>
    </>
  )
};
export default QuestionViewDrawer;

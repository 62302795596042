import Section from "./SectionComponent";
import QuestionChange from "./QuestionChange";
import NextButton from "./NextButton";
import { current } from "@reduxjs/toolkit";
import { useState } from "react";

const QuizBodyHeader = ({ flaggedQuestions, answers, currentPage, quizTestsQuestions, handlePageChange, setCurrentPage, handleSubmit }) => {

  return (
    <>
      <section>
        <div className="row mx-0 ">
          <div className="col-12 ">
            <div className="row d-flex py-2 justify-content-around align-items-center Quiz-section">
              <div className="col-lg-2 col-md-2">
                {
                  currentPage > 0 && (
                    <NextButton currentPage={currentPage} name={'Prev'} handlePageChange={handlePageChange}></NextButton>
                  )}
              </div>
              {/* <div className="col-lg-3 col-md-3">
                <Section></Section>
              </div> */}
              <div className="col-lg-5 col-md-5">
                <QuestionChange currentPage={currentPage} answers={answers} flaggedQuestions={flaggedQuestions} quizTestsQuestions={quizTestsQuestions} handlePageChange={handlePageChange} setCurrentPage={setCurrentPage} ></QuestionChange>
              </div>
              <div className="col-lg-2  col-md-2 text-end">

                {currentPage < quizTestsQuestions.length - 1 ? (
                  <NextButton currentPage={currentPage} totalQuestions={quizTestsQuestions?.length - 1} name={'Next'} handlePageChange={handlePageChange} ></NextButton>
                ) : (
                  <button onClick={() => handleSubmit()} className="btn btn-outline-primary text-center px-lg-3 ">Finish test</button>
                )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default QuizBodyHeader
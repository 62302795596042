import axios from "axios";
import * as actions from "./actionCreators";
import DatacodeConfig from "../../../core/config";
import * as constants from "../constants";
import { generateQueryParams } from "../../../utils";

const baseURL = DatacodeConfig.baseURL;

//quiz test apis 

export const createQuizTest = (quizTest) => (dispatch) => {
  dispatch({ type: constants.CREATE_TEST_LOADING })
  return axios
    .post(`${baseURL}/quiz/test/create`, quizTest)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: constants.CREATE_TEST_LOADING });
        return { success: true, data: res.data };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("Create Test Error", error);
    });
};

export const getQuizTestsList = () => (dispatch) => {
  dispatch({ type: constants.GET_QUIZ_TEST_LIST_LOADING });
  return axios
    .get(`${baseURL}/quiz/test`)
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: constants.GET_QUIZ_TEST_LIST_LOADING });
        dispatch(actions.setQuizTestsList(data));
        return { success: true, data: data.quizTests };
      }
    })
    .catch((error) => {
      console.log("get quiz tests error", error);
    });
};

export const deleteQuizTest = (quizTest) => (dispatch) => {
  dispatch({ type: constants.DELETE_LOADING })
  return axios
    .delete(`${baseURL}/quiz/test/${quizTest}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: constants.DELETE_LOADING });
        dispatch(actions.setQuizTestsList(data));
        return { success: true };
      }
    })
    .catch((error) => {
      console.log("Error in deleting test", error);
    })
};

export const EditQuizTest = (data) => (dispatch) => {
  dispatch({ type: constants.GET_TEST_DATA_LOADING });
  return axios
    .patch(`${baseURL}/quiz/test/${data.quizTest}`, { data: data })
    .then(({ data }) => {
      if (data) {
        dispatch({ type: constants.GET_TEST_DATA_LOADING });
        dispatch(actions.setQuizTestDetails(data));
        return { success: true };
      }
    })
    .catch((error) => {
      console.log("Error in Edit", error);
    })
};

//test details api

export const getQuizTestDetails = ({ quizTest, mode }) => (dispatch) => {
  dispatch({ type: constants.GET_TEST_DATA_LOADING });
  return axios
    .get(`${baseURL}/quiz/test/${quizTest}${generateQueryParams({ mode })}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: constants.GET_TEST_DATA_LOADING });
        dispatch(actions.setQuizTestDetails(data));
        return { success: true, data: data };
      }
    })
    .catch((error) => {
      console.log("get quiz tests error", error);
    });
};

export const getTestTakens = (quizTest) => (dispatch) => {
  dispatch({ type: constants.GET_TEST_TAKEN_LOADING });
  return axios
    .get(`${baseURL}/quiz/reports/${generateQueryParams({ quizTest: quizTest })}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: constants.GET_TEST_TAKEN_LOADING });
        dispatch(actions.setQuizOverviewTestTaken(data));
        return { success: true, data: data };
      }
    })
    .catch((error) => {
      console.log("get quiz tests taken error", error);
    });
}

//Candidate report review api
export const getCandidateReviewReport = (id) => (dispatch) => {
  console.log(id,"iddddddddd");
  dispatch({ type: constants.GET_CANDIDATE_DETAILS_LOADING });
  return axios
    .get(`${baseURL}/quiz/test/report/${id}`)
    .then((res) => {
      if (res.status == 200) {
        dispatch({ type: constants.GET_CANDIDATE_DETAILS_LOADING });
        dispatch(actions.setReportReviewDetails(res.data));
        return { success: true, data: res.data };
      }
    })
    .catch((error) => {
      console.log("get quiz tests taken error", error);
    });
}


//quiz test question apis

export const createQuizQuestion = (question) => (dispatch) => {
  dispatch({ type: constants.CREATE_QUESTION_LOADING });
  return axios
    .post(`${baseURL}/dayscode/quiz/create`, question)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: constants.CREATE_QUESTION_LOADING });
        return { success: true };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("Add Question Error", error);
    });
};

export const getQuizQuestionsList = (quizTest) => (dispatch) => {
  dispatch({ type: constants.GET_TEST_DATA_LOADING })
  console.log("loading");
  return axios
    .get(`${baseURL}/dayscode/quiz${generateQueryParams({
      quizTest: quizTest
    })}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch({ type: constants.GET_TEST_DATA_LOADING });
        dispatch(actions.setTestQuizQuestions(data));
        console.log("loaded");
        return { success: true, data: data };
      }
    })
    .catch((error) => {
      console.log("getting quiz questions error", error);
    });
};

export const getQuizQuestionDetails = (id) => (dispatch) => {
  return axios
    .get(`${baseURL}/dayscode/quiz/${id}`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(actions.setQuestionDetail(data));
        return { success: true, data: data };
      }
    })
    .catch((error) => {
      console.log("get quiz test details error", error);
    });
};

export const deleteQuizQuestion = (id) => (dispatch) => {
  dispatch({ type: constants.DELETE_LOADING })
  return axios.delete(`${baseURL}/dayscode/quiz/${id}${generateQueryParams({
    quizTest: id
  })}`)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: constants.DELETE_LOADING })
        return { success: true }
      }
    })
    .catch((error) => {
      console.log("Error in deleting question", error);
    })
}

export const editQuizQuestion = (formdata) => (dispatch) => {
  return axios
    .patch(`${baseURL}/dayscode/quiz/${formdata._id}`, { data: formdata })
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.setQuestionDetail(res.data))
        return { success: true, data: res.data }
      }
    })
    .catch((error) => {
      console.log("Error in editing question", error);
    })
}

//submit test 
export const submitQuizTest = (quizTest) => (dispatch) => {
  dispatch({ type: constants.SUBMITTING_QUIZ_TEST_LOADING })
  return axios
    .post(`${baseURL}/quiz/test/${quizTest.quizTestId}/submit`, quizTest)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: constants.SUBMITTING_QUIZ_TEST_LOADING })
        localStorage.setItem("userDetails",JSON.stringify({data: res.data.currentUser}));
        dispatch(actions.setReportDetail(res.data))
        return { success: true, data: res.data };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.log("Create Test Error", error);
    });
};


//  login user Details

export const getLoginUserDetail = () => (dispatch) => {
  dispatch({type:constants.GET_LOGIN_USER_DETAILS})
  return axios
  .post(`${baseURL}/dayscode/quiz/login`)
  .then((res) => {
    console.log('Response status:', res.status); // Log the status code
    if (res.status === 200) {
      dispatch({ type: constants.GET_LOGIN_USER_DETAILS });
      return { success: true, data: res.data };
    } else {
      console.log('Failed login response:', res.data); // Log the failed response data
      return { success: false };
    }
  })
  .catch((error) => {
    console.log('API error:', error.response ? error.response.data : error.message);
    return { success: false }; // Ensure false is returned on error
  });

};



const QuestionViewHeader =({setIsEditDrawer})=>{
 
    return(
      <>
        <div className="col-12 pt-2 d-flex question-view-header">
          <div className="col-lg-6 p-2 ">
            <h4>Multiple Choice Question</h4>
          </div>
          <div className="col-lg-6 buttons">
            <button className="edit-que-btn mr-4" onClick={()=>{setIsEditDrawer(true)}}>Edit a question</button>
            <button className="try-que-btn mr-4">Try Question</button>
          </div>
        </div>
      </>
    )
};

export default QuestionViewHeader;
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsFunnel from 'highcharts/modules/funnel';

// Initialize the funnel module
HighchartsFunnel(Highcharts);

const VerticalGridlineFunnelChart = () => {
  const options = {
    chart: {
      type: 'funnel',
      inverted: false,
      width:300,
      height:450,
      events: {
        load: function () {
          this.container.style.transform = 'rotate(-90deg)';
          this.container.style.transformOrigin = '70% 40%';
        }}
    },
    title: {
      text: null,
    },
    plotOptions: {
      funnel: {
        dataLabels: {
          enabled: true,
        },
      }
    },
    legend: {
      enabled: false,
    },
    series: [{
      name:'candiadte',
      data: [
        ['Invited', 1005],
        ['TestAttempted', 104],
        ['Cut off cleared', 63],
        ['Shortlisted', 10],
      ],
    }],
  };

  return (  
      <HighchartsReact highcharts={Highcharts} options={options} />
)
};

export default VerticalGridlineFunnelChart;

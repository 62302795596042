import Highcharts, { format, Legend } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);
const CandidateDistributionChart =()=>{
  
  const options = {
    chart: {
      type: 'columnrange',
      inverted: false,
      height:300,
  },
  title: {
      text: null,
  },
  xAxis: {
    title :{text: 'Candidates'},
    categories: [
            '0', '8', '16', '32', '40', '48', 
        ],
        gridLineWidth: 1,
        gridLineColor: '#cccccc',
      gridLineDashStyle: 'Dash'
  },

  yAxis: {
      title: {
          text: 'Score in percentage ( % )'
      },
      gridLineWidth: 1,
      gridLineColor: '#cccccc',  // Grey color for grid lines
      gridLineDashStyle: 'Dash',
  },

  plotOptions: {
      columnrange: {
          dataLabels: {
              enabled: true,
              verticalAlign: 'bottom', 
              formatter: function () {
                return this.y+"%"  ; 
              },
              series: {
                pointWidth: 10, 
                pointPadding: 0.5, 
                groupPadding: 0.2 
            }
          }
      }
  },
  legend: {
      enabled: false
  },

  series: [{
    name:"Percentage",
      data: [
          [10,20],
          [20,39],
          [30,49],
          [40,59],
          [20,59],
      ]
  }]

  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default CandidateDistributionChart;
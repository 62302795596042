// import { useState } from "react";
// import { RiEraserLine } from "react-icons/ri";
// import { reset } from "redux-form";

// const QuizBodyOptions = ({ handleOptionChange, quizTestsQuestions, currentPage, answers }) => {
//   const { _id, title, option1, option2, option3, option4 } = quizTestsQuestions && quizTestsQuestions?.[currentPage] || {};

//   // Find the selected answer for the current question
//   const selectedAnswer = answers && answers.find(answer => answer.questionId === _id)?.selectedAnswer;

//   const handleClearResponse = () => {
//     handleOptionChange(_id, null); // Set the selectedAnswer to null to clear the response
//   };
  
//   return (
//     <>
//       <section>
//         <div className="row mx-0">
//           <div className="col-12 my-0 pt-3 mb-lg-5 pb-lg-5  pb-sm-5">
//             <div className="d-flex justify-content-between align-items-center quizAnswer-section">
//               <div className="QuizAnswerHead fs-6">Select an option</div>
//               <div onClick={() => handleClearResponse()} className="QuizAnswerHead-2"><span className="py-0 fs-6"><RiEraserLine /></span><span href="#" className="text-decoration-none ms-2 fs-6">Clear Response</span></div>
//             </div>
//             {/* option start */}
//             <div className="options-container pt-4">
//               {[option1, option2, option3, option4].map((option, optIndex) => (
//                 <>
//                   <div className="pb-lg-3" key={optIndex}>
//                     <div className="form-check QuizAnswer-option rounded py-3 align-items-center  d-flex">
//                       <div className="ps-lg-2 py-0">
//                         <input className="form-check-input" type="radio" 
//                           name={`question-${_id}`}
//                           value={option}
//                           checked={selectedAnswer === option} // Check if this option was previously selected
//                           onChange={() => handleOptionChange(_id, option)}
//                           id={`question-${_id}`} />
//                       </div>
//                       <label className="form-check-label text-start  py-0" for="option1">
//                         {option}
//                       </label>
//                     </div>
//                   </div>
//                 </>
//               ))}
//             </div>
//           </div>
//         </div>

//       </section>
//     </>
//   )
// }
// export default QuizBodyOptions;


import { RiEraserLine } from "react-icons/ri";

const QuizBodyOptions = ({ handleOptionChange, quizTestsQuestions, currentPage, answers }) => {
  const { _id, option1, option2, option3, option4 } = quizTestsQuestions && quizTestsQuestions?.[currentPage] || {};

  // Find the selected answer for the current question
  const selectedAnswer = answers && answers.find(answer => answer.questionId === _id)?.selectedAnswer;

  const handleClearResponse = () => {
    handleOptionChange(_id, null); // Set the selectedAnswer to null to clear the response
  };

  return (
    <section>
      <div className="row mx-0">
        <div className="col-12 my-0 pt-3 mb-lg-5 pb-lg-5 pb-sm-5">
          <div className="d-flex justify-content-between align-items-center quizAnswer-section">
            <div className="QuizAnswerHead fs-6">Select an option</div>
            <div onClick={handleClearResponse} className="QuizAnswerHead-2">
              <span className="py-0 fs-6"><RiEraserLine /></span>
              <span className="text-decoration-none ms-2 fs-6">Clear Response</span>
            </div>
          </div>
          {/* Options start */}
          <div className="options-container pt-4">
            {[option1, option2, option3, option4].map((option, optIndex) => {
              const isSelected = selectedAnswer === option;
              return (
                <div
                  className="pb-lg-3"
                  key={optIndex}
                  onClick={() => handleOptionChange(_id, option)} // Make the div clickable
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => e.key === 'Enter' && handleOptionChange(_id, option)} // Enable keyboard selection with Enter
                >
                  <div className={`form-check QuizAnswer-option rounded border-primary-subtle py-3 align-items-center d-flex ${isSelected ? 'border border-primary' : ''}`}>
                    <div className="ps-lg-2 py-0 option">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`question-${_id}`}
                        value={option}
                        checked={selectedAnswer === option} // Check if this option was previously selected
                        onChange={() => handleOptionChange(_id, option)}
                        id={`question-${_id}-option-${optIndex}`} // Unique id for each radio button
                      />
                    </div>
                    <label
                      className={`form-check-label text-start py-0 ${isSelected ? 'text-primary ' : ''}`} // Change text color if selected
                      htmlFor={`question-${_id}-option-${optIndex}`} // Corresponding id for the label
                    >
                      {option}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuizBodyOptions;

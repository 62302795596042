import CandidateDistributionChart from "../Charts/CandidateDistributionChart";
const CandidatesTestScoreDistribution =()=>{

  return(
    <>
      <div className="col-12 p-3 mb-3 candidate-score">
        <h6>Distribution of Candidates based on Score </h6>
        <p>The graph displays the distribution of scores achieved by all the candidates who have attempt the test</p>
        <div className="col-12">
          <div className="col-12 py-3 table-responsive">
            <CandidateDistributionChart />
          </div>
          <div className="col-12">
            <p>No. of Candidates who scored greater than or equal to the cut off score (40%): <strong>63</strong></p>
            <p>No. of Candidates who scored greater than or equal to the median score (49%): <strong>52</strong></p>
         
          </div>
        </div>
      </div>
    </>
  )
};

export default CandidatesTestScoreDistribution;
import AttemptChart from "../Charts/AttemptsChart";

const QuestionAnalyticsRowCard = () => {
  const data = [
    {
      question: "Which one of these is floor division?",
      score: 5.0,
      total_candidates: 104,
      totalAttempt: 100,
      correctAttempt: 88,
    },
    {
      question: "Which of the following is not keyword?",
      score: 5.0,
      total_candidates: 100,
      totalAttempt: 90,
      correctAttempt: 68,
    },
  ];
  return data.map((item, index) => (
    <div className="row mx-0 mb-3 question-analysis-card ">
      <div className="col-lg-12 p-2 d-flex justify-content-between border-bottom">
        <span>Question: {index + 1}</span>
        <span>Score: {item.score}</span>
      </div>
      <div className="col-lg-12 px-0 d-flex table-responsive-md question-card-response">
        <div className="col-lg-6 p-2">
          <p className="ques">{item.question}</p>
        </div>
        <span className="line-h"></span>
        <div className="col-lg-6 p-2">
          <div className="row mx-0 d-flex justify-content-between question-card-response-A">
            <div className="col-6  px-0">Candidates Attempted</div>
            <div className="col-6 px-0 d-flex justify-content-end">
              Total Candidates:{item.total_candidates}{" "}
            </div>
          </div>
          <div className="row mx-0 px-0">
            <AttemptChart
              totalAttempt={item.totalAttempt}
              correctAttempt={item.correctAttempt}
            />
          </div>
        </div>
      </div>
    </div>
  ));
};

export default QuestionAnalyticsRowCard;

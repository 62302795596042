import React, { useEffect } from "react";
import { jwtDecode } from 'jwt-decode';
import { connect, useDispatch } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import ContributorHome from "../components/quiz_board/component/QuizTest/contributor_home";
import CreateQuizSettings from "../components/quiz_board/component/QuizTest/QuizTestDashbaord/settings";
import Login from "../components/user/component/login";
import NotFound from "../components/user/component/NotFound";
import QuizBoardHome from "../components/quiz_board/component/Quizboard/QuizBoardHome";
import QuizBoardLanding from "../components/quiz_board/component/Quizboard/QuizBoardLanding";
import { setTestUser } from "../components/user/actions";
import ForgotPassword from "../components/user/component/forgot_password";
import Confirmation from "../components/user/component/Confirmation";
import ResetPassword from "../components/user/component/reset_password";
import SocialMediaLinks from "../components/user/component/social_media/SocialMediaLinks";
import SpeakerRegistration from "../components/user/component/speaker_registration";
import QuizTestDashboard from "../components/quiz_board/component/QuizTest/QuizTestDashbaord";
import QuizTestsList from "../components/quiz_board/component/QuizTest/contributor_home/QuizTestsList";
import QuizPlayboard from "../components/QuizPlayboard/component/QuizPlayboard/QuizPlayboard";
import TestReport from "../components/QuizPlayboard/TestReport";
import ReportReview from "../components/quiz_board/component/QuizTest/QuizTestDashbaord/ReportReview/ReportReview";
import QuizTestPage from "../components/QuizTestPage";
import AssessmentTest from "../components/Assessment/component/AssessmentTest";
import Assessment from "../components/Assessment/component/assessment";
import AssessmentSkillTest from "../components/Assessment/component/AssessmentSkillTest";
import WelcomeTestInstruction from "../components/Assessment/component/AssessmentSkillTest/WelcomeTestInstruction";
import AssessmentReport from "../components/Assessment/component/Report/AssessmentReport";


const AppRouters = () => {
  const location = useLocation();
  const isAuthenticated = () => !!localStorage.getItem("userDetails");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // Extract specific query parameters
    const testUserToken = queryParams.get('e'); 
      if (testUserToken) {
      localStorage.setItem(`access_token`, testUserToken);
      const decoded = jwtDecode(testUserToken);
      setTestUser(testUserToken);
      console.log("local user", decoded);
    }
  }, []);

  const authGuard = (Component) => {
    var testUser = JSON.parse(localStorage.getItem("userDetails"));
    setTestUser(testUser)
    return localStorage.getItem("userDetails") ? 
    Component : <Navigate to="/login" />
  };

  const redirectIfAuthenticated = (Component) => {
    return isAuthenticated() ? <Navigate to="/quiz" /> : Component;
  };

  return (
    <>
      <Routes>
        <Route exact path="/" element={<QuizBoardLanding/>} />
        <Route exact path="/quiz" element={authGuard(<QuizBoardHome />)} />
        <Route exact path="/quiz/tests" element={authGuard(<QuizTestsList />)} />
        {/* <Route exact path="/quiz/test/:id" element={<QuizTestDashboard />} /> */}
        <Route exact path="/quiz/test/:id" element={authGuard(<QuizTestPage />)} />
        <Route exact path="/quiz/test/review/report/:id" element={authGuard(<ReportReview />)} />
        {/* <Route exact path="/login" element={<Login />} /> */}
        <Route exact path="/login" element={redirectIfAuthenticated(<Login />)} />
        <Route exact path="/confirmation" element={<Confirmation />} />
        <Route exact path="/forgot_password" element={<ForgotPassword />} />
        <Route exact path="/reset_password" element={<ResetPassword />} />
        <Route path="/contributor_home" element={<ContributorHome />} />
        {/* <Route exact path="/create_quiz_test_question" element={<CreateQuestion />} /> */}
        <Route exact path="/create_quiz_test_settings" element={<CreateQuizSettings />} />
        {/* -------------------- */}
        <Route exact path="/social_media" element={<SocialMediaLinks />} />
        <Route exact path="/speaker_form" element={<SpeakerRegistration />} />
        {/* ------------------------ */}
        <Route exact path="/test/:id" element={<QuizTestPage />} />
        <Route exact path="/:id/playground" element={authGuard(<QuizPlayboard />)} />
        <Route exact path="/:id/report" element={<TestReport />} />
        {/* <Route exact path="/assessment" element={<AssessmentTest />} /> */}
        <Route exact path="/assessments" element={authGuard(<AssessmentSkillTest />)} />
        <Route exact path="/assessments/:id" element={authGuard(<WelcomeTestInstruction />)} />
        <Route exact path="/assessment/test/:id" element={authGuard(<Assessment />)}/>
        <Route exact path="/assessment/test/:id/report" element={<AssessmentReport />}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

const mapDispatchToProps = {
  setTestUser,
};

export default connect(null, mapDispatchToProps)(AppRouters);
import QuestionAnalyticsRowCard from "./QuestionAnalyticsRowCard";

const TestQuestionAnalytics =({setActiveTab ,activeTab})=>{
  return(
    <>
      <div className="row mx-0 p-3 quiz-test-overview">
        <div className="col-12 px-0 question-analytics">
          <div className="row mx-0">
            <div className="col-12 px-0">
              <h4>Multiple Choice</h4>
              <span className="d-block line-v"></span>
              <h5 className="p-2">2 Questions</h5>
            </div>
            <div className="col-12 px-0">
              <QuestionAnalyticsRowCard />
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default TestQuestionAnalytics;
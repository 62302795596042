import { STOP_TIMER, UPDATE_TIME } from "../Constants";

const initialState = {
  time: { hours: 0, minutes: 1, seconds: 0 }, // Example initial time (2 hours)
  isActive: true, // Make sure the timer is active at the start
};

// Action handlers mapped to types
const actionHandlers = {
  [UPDATE_TIME]: (state, action) => ({
    ...state,
    time: action.payload,
  }),
  
  [STOP_TIMER]: (state) => ({
    ...state,
    isActive: false,
  }),
};

const timerReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];  // Get the handler for the action type
  return handler ? handler(state, action) : state;  // If the handler exists, call it; otherwise return the current state
};

export default timerReducer;

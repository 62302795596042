import { useState } from "react";
import QuestionEditor from "../Question/QuestionCreateEdit/QuestionEditor";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import { EditQuizTest} from "../../../../actions/operations";

const FAQsSection = () => {
  const {currentQuizTest} = useSelector(state => state.quizTest);
  const [isEdit ,setIsEdit] =useState(false);
  const [faQs,setFaqs] =useState(currentQuizTest?.faqs ||'<p></p>');
  const {id} = useParams();
  const dispatch = useDispatch();

  const handleSaveClick = () => {
    setIsEdit(false);
    dispatch(EditQuizTest({ quizTest: id, faqs: faQs }));
  };

  return (
    <div className="row mx-0 px-0 mb-4">
      <div className="col-12 px-0 py-2 d-flex align-items-baseline">
        <span>
          <h5>FAQs</h5>
        </span>
        <span>
          {isEdit ? <a onClick={handleSaveClick} className="m-2">Save</a> : <a onClick={() =>setIsEdit(true)} className="m-2">Edit</a>}
        </span>
      </div>
      <div className="col-12 p-4 faq-section">
        {!isEdit ? (
          <div className="p-2">{currentQuizTest && currentQuizTest.faqs && parse(currentQuizTest?.faqs)}</div>
        ) : (
          currentQuizTest &&
          <QuestionEditor
            text={faQs}
            handleTextEditor={setFaqs}
          />
        )}
      </div>
    </div>
  );
};

export default FAQsSection;

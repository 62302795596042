import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ReportSkillPerformanceChart = () => {
  const categories = ['Aptitude', 'Programming', 'Communication'];
  const data = [
    { y: 0, x: 85 }, 
    { y: 1, x: 90 }, 
    { y: 2, x: 78 }, 
  ];
  const options = {
    chart: {
      type: 'line',
      height: 300,
    },
    title: null,
    xAxis: {
      title: {
        text: 'Average Marks',
      },
    },
    yAxis: {
      title: {
        text: 'Subjects',
      },
      categories: categories,
    },
    series: [
      {
        name: 'Average Marks',
        data: data,
        marker: {
          enabled: true,
        },
      },
    ],
    tooltip: {
      formatter: function () {
        const subject = categories[this.point.y]; // Get the subject name based on the y value
        return `${this.series.name}: ${this.x} marks in ${subject}`;
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ReportSkillPerformanceChart;

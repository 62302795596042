import "../../../../../../assets/scss/quiz_Dashboard/questionLevelList.scss";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Menu } from "antd";
import { useState } from "react";

const data = [{ name: "DataCode", email: "datacode.in@gmail.com", access: "" }];

const QuizTestAdmins = () => {
  const [accessibility, setAccessibility] = useState("All Access");

  const menuItems = [
    { label: "Read Only", key: '0', onClick: () => setAccessibility("Read Only") },
    { label: "Edit Only", key: '1', onClick: () => setAccessibility("Edit Only") },
    { label: "All Access", key: '2', onClick: () => setAccessibility("All Access") }
  ];

  const menu = {
    items: menuItems
  };

  return (
    <>
      <div className="row mx-0 px-0 mb-4 admins-list">
        <div className="col-12 py-1 px-0 d-flex justify-content-between">
          <div className="col-6">
            <h5>Test admins</h5>
          </div>
          <div className="col-6 d-flex justify-content-end align-items-baseline">
            <span className="mr-2">
              Point of Contact <i className="bi bi-exclamation-circle"></i>
            </span>
            <span className="mr-2">
              <select className="form-select select-dashboard" id="accessSelect">
                <option selected>DataCode</option>
                <option value="1">*</option>
                <option value="2">*</option>
              </select>
            </span>
            <span className="mr-2">
              <a href="">
                <i className="bi bi-person-plus"></i>Add Admins{" "}
              </a>
            </span>
          </div>
        </div>
        <div className="col-12 px-0 table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email Id</th>
                <th scope="col">Access Controls</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>
                    <Dropdown menu={menu} trigger={["click"]}>
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          {accessibility}
                          <DownOutlined />
                        </Space>
                      </a>
                    </Dropdown>
                  </td>
                  <td>
                    <a href="">
                      <i className="bi bi-trash"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default QuizTestAdmins;

import { useSelector } from "react-redux";

const AboutCandidateDetails = () => {
  const {candidateReportReviewDetails } = useSelector(state => state.quizTest);

  const {email,university, phone ,imgUrl} = candidateReportReviewDetails?.user || {} ;

  return (
    candidateReportReviewDetails &&
    <div className="col-12 px-4 py-3 mt-3 about-candidate">
      <div className="row mx-0">
        <div className="col-12 px-0">
          <p className="heading-para">About Username</p>
        </div>
        <div className="col-lg-12 px-0 candidate-details">
          <div className="row mx-0">
            <div className="col-lg-2 p-2">
              <img src={imgUrl} alt="userimage" width="50px" height="50px"/>
            </div>
            <div className="col-lg-5">
              <span className="d-flex flex-column p-2">
                <span className="about-heading">
                <i className="bi bi-backpack3"></i> Education
                </span>
                <span>{university}</span>
              </span>
              <span className="d-flex flex-column p-2">
                <span className="about-heading">
                <i className="bi bi-code-slash"></i> Languages
                </span>
                <span>Python 3</span>
              </span>
              <span className="d-flex flex-column p-2">
                <span className="about-heading">
                <i className="bi bi-telephone"></i> Contact Number
                </span>
                <span>{phone}</span>
              </span>
            </div>
            <div className="col-5">
              <span className="d-flex flex-column p-2">
                <span className="about-heading">
                <i className="bi bi-envelope"></i> Email
                </span>
                <span>{email}</span>
              </span>
              <span className="d-flex flex-column p-2">
                <span className="about-heading">
                <i className="bi bi-geo-alt"></i> Location
                </span>
                <span>Mandsaur</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCandidateDetails;

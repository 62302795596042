import { useSelector } from "react-redux";

const ReviewReportDescription =() =>{
  const {candidateReportReviewDetails} = useSelector(state => state.quizTest);
  const { responses } = candidateReportReviewDetails;
  return(
     responses &&
    <div className="col-12  mt-3 d-lg-flex report-description">
      <div className="col-lg-3 p-3">
        <span className="tag-desc">RANK</span>
        <span className="d-flex align-items-baseline">
          <h2>1</h2>
          <p>/31</p>
        </span>
      </div>
      <div className="col-lg-3 p-3">
        <span className="tag-desc">TOTAL SCORE</span>
        <span className="d-flex align-items-baseline">
        <i className="bi bi-check-circle h5 m-2"></i> 
          <h2>98</h2>
          <p>/100</p>
        </span>
      </div>
      <div className="col-lg-3 p-3">
        <span className="tag-desc">ATTEMPTED</span>
        <span className="d-flex align-items-baseline">
        <i></i>
          <h2>5</h2>
          <p>{" "} of {responses.length} questions</p>
        </span>
      </div>
    </div>
  )
}

export default ReviewReportDescription;
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';


const candidate_info = [
  {
    id:"1",
    candidate_email: "ms898755676@gmail.com",
    candidate_name:"Nitish Gandhi",
    status: "Review Pending",
    expiry_date:"",
    invited_on: "On Mar 2023,4:00 PM IST (Asia/Kolkata)",
    invited_by:"akshaycoder@gmail.com",
  },
  {
    id:"2",
    candidate_email: "ms0009898755676@gmail.com",
    candidate_name:"Nitish Gandhi",
    status: "Review Pending",
    expiry_date:"",
    invited_on: "On Mar 2023,4:00 PM IST (Asia/Kolkata)",
    invited_by:"akshaycoder@gmail.com",
  },
];

const headerStyle={width:"20%",fontWeight: '500',margin:"10px",padding:"10px",fontSize:"16px"
};

const columns = [
  {
    dataField: 'id',
    text: '#',
    headerStyle:{color:"grey",fontWeight: '500',textAlign:"center"},
  },
  {
    dataField: 'candidate_email',
    text: 'Candidate Email',
    headerStyle:headerStyle,
  },
  {
    dataField: 'candidate_name',
    text: 'Candidate Name',
    headerStyle:headerStyle,
  },
  {
    dataField: 'status',
    text: 'Status',
    headerStyle:headerStyle,
  },
  {
    dataField: 'expiry_date',
    text: 'Expiry Date',
    headerStyle:headerStyle,
  },
  {
    dataField: 'invited_on',
    text: 'Invited On',
    headerStyle:headerStyle,
  },
  {
    dataField: 'invited_by',
    text: 'Invited By',
    headerStyle:headerStyle,
  },
];

const selectRow = {
  mode: 'checkbox',
  clickToSelect: true,
};

const CandidatesInvitedTable =()=>{
  return(
    <>
    <div className="invited-table table-responsive-sm">
      <BootstrapTable
      keyField='id'
      data={candidate_info}
      columns={columns}
      bordered={false}
      selectRow={selectRow}
    />
    </div>
    </>
  )
};

export default CandidatesInvitedTable;
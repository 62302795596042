import CreateQuestionForm from "./CreateQuestionForm";
import { connect } from "react-redux";

// import { addQuestion } from "../../../../../actions";

const mapStateToProps = ({ quizTest }) => ({
  currentQuizTest: quizTest.currentQuizTest,
  quizQuestions: quizTest.quizQuestions,
});



export default connect(mapStateToProps, null)(CreateQuestionForm);

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const AttemptChart = ({ totalAttempt, correctAttempt }) => {
  const options = {
    chart: {
      type: 'bar',
      backgroundColor: 'transparent',
      height: 105, 
    },
    title: {
      text: null 
    },
    xAxis: {
      categories: [ `Correct Attempts: ${correctAttempt}`,
        `Total Attempts: ${totalAttempt}`,],
      labels: {
        style: {
          color: 'white',
          inside: true 
        },
        align: 'left', 
        x: 5 ,
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: null 
      },
      gridLineWidth: 0 
    },
    plotOptions: {
      series: {
        pointWidth: 20, 
        pointPadding: 0.3, 
        groupPadding: 0.4
      }

    },
    series: [{
      name: 'Attempts',
      data: [
        { y:correctAttempt, color: 'green' } ,
        { y: totalAttempt, color: 'blue' }, 
      ]
    }],
    legend: {
      enabled: false 
    },
    credits: {
      enabled: false 
    }
  };

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </>
  );
};

export default AttemptChart;

const ReportTestTimeAnalysis = () => {
  return (
    <div className="col-12 mt-3 px-4 py-3 time-analysis">
      <div className="row mx-0">
        <div className="col-12 px-0">
          <h6 >Test Time Analysis</h6>
        </div>
        <div className="col-12 px-0 d-lg-flex">
          <div className="col-lg-3 p-0">
            <span className="tag-desc">TEST START TIME</span>
            <p>Nov 23 , 2023 </p>
          </div>
          <div className="col-lg-3 p-0">
            <span className="tag-desc">TEST END TIME</span>
            <p>Nov 23 , 2023 </p>
          </div>
          <div className="col-lg-3 p-0 ">
            <span className="tag-desc">TEST DURATION</span>
            <p>Nov 23 , 2023 </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportTestTimeAnalysis;

import VerticalGridlineFunnelChart from "../Charts/FunnelChart";

const CandidateHiringFunnel =()=>{
  return(
    <>
      <div className="col-12 p-3 mb-3 candidate-funnel">
        <h5>Candidate Hiring Funnel</h5>
        <p>10% of the total invited Candidates have attempted the the test</p>
        <div className="col-12 px-0 table-responsive">
        <VerticalGridlineFunnelChart />
      </div>
      </div>
    </>
  )
};

export default CandidateHiringFunnel;
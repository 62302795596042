import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Navigate, Link } from 'react-router-dom';

import { renderInputField } from '../../../../shared_components/ReduxFormFields';
import { required } from '../../../../utils/validators';
import { ReactComponent as Code } from '../../../../assets/images/code_1.svg';
import { ReactComponent as GoogleLogo } from "../../../../assets/images/google.svg";
import Loader from '../../../../shared_components/Loader';
import { useSelector } from 'react-redux';

const Login = ({
  reset, handleSubmit, submitting, loginUser,
}) => {
  const [userDetails, setUserDetails] = useState();
  const [error, setError] = useState('');
  const { currentUserLoading } = useSelector(state => state.user);

  const onSubmit = (values) => {
    const user = { ...values };
    loginUser(user).then((res) => {
      if (res.success || res.data.success ) {
        setError("")
        setUserDetails(res.data);
      } else {
        setError(res.message || res.data.message);
      }
    });
    reset('loginForm');
  };

  return (
    <>
      {userDetails && !error && <Navigate to="/quiz" />}
      <div className="row mx-0 px-0 login ">
        <div className="col-12 col-md-8 img-section p-5">
          <div className="p-4">
            <Code width="500px" height="500px" />
          </div>
        </div>

        <div className="col-12 col-md-4 login-section">
          {currentUserLoading ? (
            <Loader />
          ) : (
            <>
              <div className="pt-md-3 px-4 login-card">
                <h1 className="text-center login-text">Log in To Get Inside</h1>
                <button type="button" className="google-button">
                  <GoogleLogo width="40" height="40" className="bg-white p-1 mr-5" />
                  <div className="google-login-string">Log in with Google</div>
                </button>
                <div className="or-line">
                  <span>or</span>
                </div>
                {error && <p className="text-danger">{error}</p>}
                <form  onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <Field
                      name="email"
                      type="text"
                      component={renderInputField}
                      label="Username or Email"
                      placeholder="system123"
                      validate={[required]}
                    />
                    <Field
                      name="password"
                      type="password"
                      component={renderInputField}
                      label="Password"
                      placeholder="******"
                      validate={[required]}
                    />
                  </div>
                  <div className="row mt-4">
                    <div className="col-7 text-left">
                      <div>
                        <p className="signup-string p-0">Don't have an account ? </p>
                        <Link to="https://datacode.in/signup" className="signup-string ml-2 link">Sign Up</Link>
                      </div>
                      <div className="text-left">
                        <Link to="https://datacode.in/forgot_password" className="signup-string">Forgot Password?</Link>
                      </div>
                    </div>
                    <div className="col-5">
                      <button type="submit" className="btn btn-primary login-button" disabled={submitting}>
                        Login
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'loginForm', // a unique name for this form
})(Login);

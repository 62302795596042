import { useNavigate } from "react-router-dom";
import { matchPath, useLocation } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import datacodelogo from "../../../../assets/images/datacodelogo.png";

const Header = () => {
  const navigate = useNavigate();
  const testUser = JSON.parse(localStorage.getItem("userDetails"));
  const location = useLocation();

  const hideHeaderRoute = matchPath("/login", location.pathname);
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  // Define menu items using the `items` prop
  const menuItems = [
    {
      key: "1",
      label: "LogOut",
      onClick: handleLogout,
    },
    // Add more items if needed
  ];

  return (
    <nav className="navbar navbar-expand-lg row mx-0 ">
      <div className="col-12 d-flex justify-content-between align-items-center">
        <div className="col-6 d-flex ps-lg-3">
          <img src={datacodelogo} alt="datacodelogo" height={50} />
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center pe-lg-3">
          {testUser && (
            <>
              <span>
                {testUser.data.firstName} {testUser.data.lastName}
              </span>
              <img
                src={testUser.data.imgUrl}
                className="border border-dark rounded-5 mx-3"
                alt="User"
                height={40}
                width={40}
              />
              <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
                <MoreOutlined style={{ fontSize: "24px", cursor: "pointer" }} />
              </Dropdown>
            </>
          )}

          {!testUser && !hideHeaderRoute && (
            <span
              className="mx-1 text-primary fs-5"
              style={{ cursor: "pointer" }}
              onClick={handleLogout}
            >
              LogIn
            </span>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;

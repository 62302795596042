"use client";

import { withErrorBoundary } from 'react-error-boundary';
import QuizTestDashboard from './QuizTestDashboard';
import ErrorFallback from '../../../../../shared_components/ErrorFallback';

function logErrorToService(error, info) {
  // Log the error to an error logging service
  console.error("Logging error:", error, info);
}

export default withErrorBoundary(QuizTestDashboard, {
  FallbackComponent: ErrorFallback,
  onError: logErrorToService,
});
import CandidatesFeedbackTable from "../Tables/CandidateFeedbackTable";

const CandidatesFeedback =({setActiveTab,activeTab})=>{
  return(
    <>
      <div className="row mx-0 p-3 candidate-feedback-section">
          <div className="col-sm-12 py-3 px-0 d-flex justify-content-between candidate-feedback">
            <h5>{activeTab}</h5>
            <button>Download Feedback (.xls)</button>
          </div>
          <div className="col-12 px-0 table-responsive">
            <CandidatesFeedbackTable />
          </div>
        </div>
    </>
  )
};

export default CandidatesFeedback;
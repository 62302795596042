import CandidatesTables from "../Tables/CandidatesTable";
import QuizTestTakenHeading from "../QuizTestTakenHeading";
const QuizReviewPending = ({ setActiveTab, activeTab }) => {
  return (
    <>
      <div className="row mx-0 p-3 testTaken-section">
        <div className="col-12 px-0">
          <QuizTestTakenHeading activeTab={activeTab} />
          <CandidatesTables />
        </div>
      </div>
    </>
  );
};

export default QuizReviewPending;

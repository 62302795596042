import QuizTestSideNavbarDrawer from "../Question/Drawers/QuizTestSideNavbarDrawer";
import QuizTestHeader from "../QuizTestHeader";
import ReportReviewHeader from "./ReportReviewHeader";
import ReviewReportDescription from "./ReportReviewDescription";
import ReportTestTimeAnalysis from "./ReportTestTimeAnalysis";
import SkillPerformance from "./ReportSkillPerformance";
import KeyObservations from "./KeyObservations";
import AboutCandidateDetails from "./AboutCandidateDetails";
import DetailedSubmissionReport from "./DetailedSubmissionReport";
import ReportCommentSection from "./ReportCommentSection";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getCandidateReviewReport } from "../../../../actions/operations";
import Loader from "../../../../../../shared_components/Loader";

const ReportReview = () => {
  const { candiateReportReviewDetailsLoading, candidateReportReviewDetails } = useSelector(state => state.quizTest);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCandidateReviewReport(id));
  }, [id]);

  return (
    candiateReportReviewDetailsLoading ?
      (
        <Loader />
      ) :
      (
        candidateReportReviewDetails &&
        <div className="row p-lg-5 mx-0 mt-5 quiz-bg">
          <div className="col-12 px-0 pt-2">
            <div className="row mx-0 d-flex justify-content-center">
              <div className="col-lg-10 px-4 review-report">
                <div className="row mx-0 py-3">
                  <ReportReviewHeader />
                  <ReviewReportDescription />
                  <ReportTestTimeAnalysis />
                  <SkillPerformance />
                  <KeyObservations />
                  <AboutCandidateDetails />
                  <DetailedSubmissionReport />
                  <ReportCommentSection />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  );

}

export default ReportReview;
import QuestionViewHeader from "./QuestionViewHeader";
import QuestionviewSidebar from "./QuestionViewSidebar";
import QuestionViewHero from "./QuestionViewHeroSection";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {getQuizQuestionDetails} from "../../../../actions/operations"
import Loader from "../../../../../../shared_components/Loader";

const QuestionView = ({setIsEditDrawer,questionId}) => {
  const {quizQuestionDetails ,quizQuestionDetailsLoading} = useSelector(state => state.quizTest)
  const dispatch = useDispatch();
  
  useEffect(()=>{
    dispatch(getQuizQuestionDetails(questionId));
  },[questionId])
  
  return (
    <>
      <div className="row mx-0 pb-0 question-view">
        <QuestionViewHeader setIsEditDrawer={setIsEditDrawer} />
        <div className="col-lg-12 p-0 justify-content-between Question-overview-A">
         <div className="row mx-0 ">
          <div className="col-8 col-sm-12">
          <QuestionViewHero quizQuestionDetails={quizQuestionDetails}/>
          </div>
          <div className="col-4 col-sm-12 ">
          <QuestionviewSidebar quizQuestionDetails={quizQuestionDetails}/>
          </div>
         </div>
        </div>
      </div>
    </>
  )
};

export default QuestionView;

import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const QuestionEditor = ({ text, handleTextEditor}) => {
  const editorRef = useRef(null);

  return (
    <Editor
      apiKey="mkgc3k6qf9u9uwdt21nuw6dburp75ler5itomraoqqcpw5kt"
      onInit={(evt, editor) => editorRef.current = editor}
      value={text}
      init={{
        height: 250,
        menubar: false,
        toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
          'alignleft aligncenter alignright alignjustify | ' +
          'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
        plugins: [
          'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
          'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
          'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
        ],
        tinycomments_mode: 'embedded',
        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
      }}
      onEditorChange={(value) => handleTextEditor(value)}
    />
  );
};

export default QuestionEditor;

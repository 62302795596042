import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';

const candidate_info = [
  {
    id:"1",   
    candidate_name:"Nitish Gandhi",
    time_stamp: "On Mar 2023,4:00 PM IST (Asia/Kolkata)",
    candidate_feedback:"Its been amazing to be part of this test its very knowledgable and interesting",
    response:""
    
  },
  {
    id:"2",
    candidate_name:"Nitish Gandhi",
    time_stamp: "On Mar 2023,4:00 PM IST (Asia/Kolkata)",
    candidate_feedback:"Its been amazing to be part of this test its very knowledgable and interesting",
    response:""
  },
];
const headerStyle={width:"20%",fontWeight: '500',margin:"10px",padding:"10px",fontSize:"16px"
};
const columns = [
  {
    dataField: 'id',
    text: '#',
    headerStyle:{color:"grey",fontWeight: '500',textAlign:"center"},
  },
  {
    dataField: 'time_stamp',
    text: 'Time Stamp',
    headerStyle:headerStyle,
  },
  {
    dataField: 'candidate_name',
    text: 'Candidate Name',
    headerStyle:headerStyle,
  },
  {
    dataField: 'candidate_feedback',
    text: 'Candidate Feedback',
    headerStyle:{width:"40%",fontWeight: '500',margin:"10px",padding:"10px",fontSize:"16px"}
  },
  
  {
    dataField: 'response',
    text: 'Response',
    headerStyle:headerStyle,
  },
];

const CandidatesFeedbackTable =()=>{
  return(
    <>
    <div className="invited-table">
      <BootstrapTable
      keyField='id'
      data={candidate_info}
      columns={columns}
      bordered={false}
    />
    </div>
    </>
  )
};

export default CandidatesFeedbackTable;
import { useEffect } from "react";
import AssessmentCard from "./AssessmentCard";
import AssessmentCardVerified from "./AssessmentCardVerified";
import AssessmentTestSkillHeader from "./AssessmentTestSkillHeader";
import { useDispatch, useSelector } from "react-redux";
import { getQuizTestsList } from "../../../quiz_board/actions";
import { Link } from "react-router-dom";

const AssessmentSkillTest = () => {
  var testUser = JSON.parse(localStorage.getItem("userDetails"));
  const currentUserId = testUser?.data._id;
  const dispatch = useDispatch();
  const { quizTestsList } = useSelector((state) => state.quizTest);

  useEffect(() => {
    dispatch(getQuizTestsList());
  }, [currentUserId]);
  return (
    <>
      {/* <div className="row mx-0 pt-5">*/}
      <div className="col-12 px-0 pt-3">
        <div className="row mx-0 Assessment-cards-section">
          <div className="col-12">
            <div className="row mx-0 px-lg-5 py-4">
              <Link
                to="/quiz"
                className="text-decoration-none text-primary d-flex align-items-center"
              >
                <i className="bi bi-arrow-left me-2"></i>
                <span className="fw-semibold">Back</span>
              </Link>
              <h4 className="ps-1 pb-4 fw-bolder">Assessment</h4>
              <div className="col-12  rounded-1  assessment-card-A py-3 px-0">
                <div className="col-12">
                  <AssessmentCard quizTestsList={quizTestsList} />
                </div>
                <div className="col-12 pt-4">
                  <AssessmentCardVerified quizTestsList={quizTestsList} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*</div> */}
    </>
  );
};
export default AssessmentSkillTest;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { EditQuizTest, getQuizTestDetails } from "../../../../actions";

const SettingsSection = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [cutOff, setCutOff] = useState(0);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { id } = useParams();
  const ProctoringData = [
    {
      title: "Enable surprise question",
      description:
        "Candidate needs to answer a surprise question after they complete the quiz.",
    },
    {
      title: "Enable surprise question",
      description:
        "Candidate needs to answer a surprise question after they complete the quiz.",
    },
    {
      title: "Enable surprise question",
      description:
        "Candidate needs to answer a surprise question after they complete the quiz.",
    },
  ];

  const handleEditSaveToggle = () => {
    if (isEdit) {
      if (cutOff < 0) {
        setError('Cut-off value must be greater than 0');
        return;
      }
      setError('');
      console.log("Saving cut-off:", cutOff);
    }
    setIsEdit(prev => !prev);
  };

  const handleCutOffChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) > 0 && !isNaN(value))) {
      setCutOff(Number(value));
      setError('');
      dispatch(EditQuizTest({ quizTest: id, quizCutOff: cutOff })).then((res) => {
        dispatch(getQuizTestDetails({ quizTest: id, mode: 'creator' }));
      })
    } else {
      setError('Cut-off value must be a positive number');
    }
  };

  return (
    <>
      <div className="row mx-0 text-left">
        <div className="col-12 py-3">
          <h5>Settings</h5>
          <p className="px-2 m-0">
            These are the recommended settings that you can enable for the test.
          </p>
        </div>
        <div className="col-12 py-3">
          <div className="d-flex align-items-baseline">
            <h6>Cut Off settings</h6>
            <a onClick={handleEditSaveToggle} className="m-2">
              {isEdit ? "Save" : "Edit"}
            </a>
          </div>
          {isEdit ? (
            <div className="px-2">
              <input
                type="number"
                name="cutOff"
                placeholder="Add cut-off"
                value={cutOff}
                onChange={handleCutOffChange}
              />
              {error && <p className="text-danger">{error}</p>}
            </div>
          ) : (
            <p className="px-2 m-0">Cut-off score: {cutOff}</p>
          )}
          <p className="px-2 m-0">Cut-off score has been set</p>
          <p className="px-2 m-0">All Cut-off settings are {cutOff > 0 ? "enabled" : "disabled"}</p>
        </div>
        <div className="col-12 py-3">
          <h6>Proctoring settings</h6>
          {ProctoringData.map((item, index) => (
            <div key={index} className="form-check form-switch py-2">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id={`flexSwitchCheckDefault-${index}`}
              />
              <label
                className="form-check-label"
                htmlFor={`flexSwitchCheckDefault-${index}`}
              >
                <h6>{item.title}</h6>
              </label>
              <div>
                <label
                  className="form-check-label"
                  htmlFor={`flexSwitchCheckCheckedDisabled-${index}`}
                >
                  {item.description}
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className="col-12 py-3">
          <div className="d-flex align-items-baseline">
            <h6>Candidate settings</h6>
            <a href="#" className="m-2">Edit</a>
          </div>
          <p className="px-2 m-0">Full Name, Personal email ID</p>
          <p className="px-2 m-0">Information that can be collected from candidates.</p>
        </div>
      </div>
    </>
  )
};

export default SettingsSection;

const AdvancedDetails = [
  {
    title: "Open Contest",
    description:
      "This option is required if you are creating a contest in ICPC format.You are required to enter start date , end date ,start time and duration time etc",
    enable: "yes",
  },
  {
    title: "Show leaderBoard",
    description: "All Show leaderboard are Disabled",
    enable: "no",
  },
  {
    title: "Create Items to participate in content",
    description: "All Create Items to participate in content",
    enable: "no",
  },
];

const AdvancedSettingSection = () => {
  return (
    <>
      <div className="row mx-0 mb-4 px-0">
        <div className="col-12 p-3 advanced-setting">
          <div className="col-12 ">
            <h5>Advanced Settings</h5>
            <p>
              These are the recommended settings that you can enable for the
              test
            </p>
          </div>
          <div className="col-12 ">
            <div className="col-12 px-0 d-flex align-items-baseline">
              <h6>College event related Settings</h6>
              <a href="" className="m-2">Edit</a>
            </div>
          </div>
          {AdvancedDetails.map((item, index) => (
            <div className="row mx-0 py-2">
              <div className="col-12 px-0 d-flex" key={index}>
                {item.enable == "yes" ? (
                  <span className="mx-2">
                    <i className="bi bi-check-circle "></i>
                  </span>
                ) : (
                  <span className="mx-2">
                    <i className="bi bi-x-circle"></i>
                  </span>
                )}
                <h6>{item.title}</h6>
              </div>
              <div>
                <p className="px-4">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
};

export default AdvancedSettingSection;

import { useSelector } from "react-redux";

const QuestionViewSidebar = ({ quizQuestionDetails }) => {
  return (
    <>
    {quizQuestionDetails &&
      <div className="col-lg-5 p-4 side-bar">
        <div className="col-lg-12 ">
          <h4>Difficulty Level</h4>
          <button type="btton" className="px-4 level-btn">{quizQuestionDetails?.level}</button>
        </div>
        <div className="col-lg-12 text-left d-lg-flex px-0 pt-3">
          <div className="col-lg-6 py-3">
            <h5>Maximum Score</h5>
            <p>{quizQuestionDetails?.maxScore}</p>
          </div>
          <div className="col-lg-5 py-3">
            <h5>Negative Score <a href=""><i className="bi bi-exclamation-circle m-2"></i></a></h5>
            <p>{quizQuestionDetails?.negativeScore}</p>
          </div>
        </div>
        <div className="col-lg-12 text-left ">
          <h5>Tags</h5>
          <span>You have not added any tag to this question</span>
        </div>
      </div>}
    </>
  )
}

export default QuestionViewSidebar;
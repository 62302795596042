
  export const Data = [
    {
      question: "What types of quizzes are available?",
      answer:
        "We offer a wide range of quizzes, including categories like general knowledge, history, science, pop culture, and many more. You can also find quizzes tailored to specific skills or interests, with options for both beginners and advanced users.",
    },
    {
      question: "How can I track my progress?",
      answer:
        "Your progress is automatically tracked through your profile, where you can view your scores, completed quizzes, and overall performance over time. Detailed stats for each quiz allow you to see your strengths and areas to improve, making it easier to track your growth.",
    },
    {
      question: "Can I compete with my friends?",
      answer:
        "Yes! You can challenge your friends to take the same quizzes and see who scores higher. Compete in real-time or compare scores after taking a quiz. It’s a fun way to stay engaged and learn together!",
    },
    {
      question: "How can I track my progress?",
      answer:
        "Your progress is automatically tracked through your profile, where you can view your scores, completed quizzes, and overall performance over time. Detailed stats for each quiz allow you to see your strengths and areas to improve, making it easier to track your growth.",
    },
    {
      question: "Can I compete with my friends?",
      answer:
        "Yes! You can challenge your friends to take the same quizzes and see who scores higher. Compete in real-time or compare scores after taking a quiz. It’s a fun way to stay engaged and learn together!",
    },
    {
      question: "How can I track my progress?",
      answer:
        "Your progress is automatically tracked through your profile, where you can view your scores, completed quizzes, and overall performance over time. Detailed stats for each quiz allow you to see your strengths and areas to improve, making it easier to track your growth.",
    },
  ];
 
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const OtherDetailsForm = ({ isEditable, OtherDetails, onSubmit }) => {
  const { currentQuizTest } = useSelector((state) => state.quizTest) ;

  const [formData, setFormData] = useState({
    quizTitle: currentQuizTest?.quizTitle || "",
    test_access: OtherDetails.test_access || "",
    startDate: currentQuizTest?.startDate || "",
    endDate: currentQuizTest?.endDate || "",
    test_link: OtherDetails.test_link || "",
    practice_test_link: OtherDetails.practice_test_link || "",
    access:currentQuizTest?.access || "",
    _id: currentQuizTest?._id || "",
    tags: currentQuizTest?.tags || [],
    quizTest: currentQuizTest?._id,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => {
      if (name === "tags") {
        const tagsArray = value.split(",").map(tag => tag.trim()).filter(tag => tag !== "");
        return {
          ...prevFormData,
          [name]: tagsArray,
        };
      }
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? (checked ? "on" : "off") : value,
      };
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData)
  };

  return (
    <> 
      {
      currentQuizTest &&
      <form className="otherdetailsform" onSubmit={handleSubmit}>
      <div className="col-12 pt-3">
        <p>Test Name</p>
        <input
          type="text"
          onChange={handleChange}
          readOnly={!isEditable}
          name="quizTitle"
          value={formData.quizTitle}
        />
      </div>
      <div className="col-12 pt-3">
        <p>Test Access</p>
        <div className="form-check form-switch py-2">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            name="test_access"
            disabled={!isEditable}
            checked={formData.test_access === "on"}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="col-12 pt-3">
        <p>Starts On</p>
        <input
          type="date"
          onChange={handleChange}
          readOnly={!isEditable}
          name="startDate"
          value={formData.startDate}
        />
      </div>
      <div className="col-12 pt-3">
        <p>Ends On</p>
        <input
          type="date"
          onChange={handleChange}
          readOnly={!isEditable}
          name="endDate"
          value={formData.endDate}
          
        />
      </div>
      <div className="col-12 pt-3">
        <p>Test Link</p>
        <input
          type="text"
          onChange={handleChange}
          readOnly={!isEditable}
          name="test_link"
          value={formData.test_link}
        />
      </div>
      <div className="col-12 pt-3">
        <p>Practice Test Link</p>
        <input
          type="text"
          onChange={handleChange}
          readOnly={!isEditable}
          name="practice_test_link"
          value={formData.practice_test_link}
        />
      </div>
      <div className="col-12 pt-3">
        <p>Test Type</p>
        <input
          type="text"
          onChange={handleChange}
          readOnly={!isEditable}
          name="access"
          value={formData.access}
        />
      </div>
      <div className="col-12 pt-3">
        <p>Assessment Id</p>
        <input
          type="text"
          readOnly
          name="_id"
          value={formData._id}
        />
      </div>
      <div className="col-12 pt-3">
        <p>Tags</p>
        <input type="text" readOnly={!isEditable} placeholder = "+ Add Tags" name="tags" value={formData.tags.join(", ")} onChange={handleChange}/>
      </div>
      {isEditable? <button type="submit" className="btn btn-primary my-3 w-30">
        Edit 
      </button>: null}
      </form>
      }
    </>
    
  )
};

export default OtherDetailsForm;
